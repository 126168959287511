import React, {useEffect, useCallback, useState } from 'react';
import './dist/buyblock.css'
import { NavLink, useNavigate } from 'react-router-dom';


export default function BuyBlock({phoneBack,nameBack,wait_send_or_call,cart}) {
    const nav = useNavigate();
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(false)
    const [currentHeighMenu, setCurrentHeighMenu] = useState()
    const [user_name,setUserName] = useState('')
    const [user_phone,setUserPhone] = useState('')


    const nameKeeper = (e) => {
        let raw_value = e.target.value
        let default_value = raw_value.charAt(0).toUpperCase() + raw_value.slice(1)

        setUserName(default_value)

    }
    useEffect(()=>{
        nameBack(user_name)
    },[user_name])

    const phoneKeeper = (e) =>{
        const rawValue = e.target.value;
        const formattedValue = rawValue
            .replace(/[^0-9+\-\s]/g, '')
            .replace(/\s+/g, '-')
        if(user_phone.length < 17){
            setUserPhone(formattedValue)
        }else {
            setUserPhone(prevState => prevState.slice(0, -1))
        }
    }

    useEffect(()=>{
        phoneBack(user_phone)
    },[user_phone])


    const totalPrice = () => {
        let price = 0
        for(let i = 0; i< cart.length;i++){
            price += parseInt(cart[i].price)
        }

        return price
    }

    useEffect(()=>{
        totalPrice()
    },[])

    const emptyCart = () => {
        let modal = document.getElementById("empty_cart_menu")
        modal.style.display = "flex"
        setTimeout(()=>{
            modal.style.transform = 'scale(1)'
            setTimeout(()=>{
                modal.style.transform = 'scale(0)'
                setTimeout(()=>{
                    modal.style.display = "none"
                },5010)
            },5000)
        },10)

    }

    const formOpener = useCallback(
        () => {
            if (isCartMenuOpen === false) {
                let BuyBlock = document.getElementById("buy_block_con")
                BuyBlock.style.height = '53.5vh';
                let CurrentMenu = document.getElementById("cart_menu")

                let CurrentMenuCurrentHeight = parseFloat(CurrentMenu.style.height.replace("px", ""))
                setCurrentHeighMenu(CurrentMenuCurrentHeight)
                let WindowHeight = window.innerHeight

                let CurrentMenuCurrentVH = (CurrentMenuCurrentHeight / WindowHeight) * 100

                CurrentMenu.style.height = `${CurrentMenuCurrentVH + 53.5}vh`



                BuyBlock.style.background = '#896121'
                BuyBlock.style.borderRadius = '1vh'

                let buy_inputs = document.getElementById("buy_inputs")
                buy_inputs.style.height = '26.1vh'
                buy_inputs.style.overflow = 'visible'

                document.getElementById("buy_button").style.color = "#B1B1B1"
                document.getElementById("close_btn_buy").style.transform = "rotate(-90deg)"
                document.getElementById("close_btn_buy").style.opacity= "1"
                setIsCartMenuOpen(true)
            } else {
                if(cart.length !== 0 && document.getElementById("name_buy").value !== '' && document.getElementById("phone_buy").value.length > 9) {
                    wait_send_or_call()
                    nav('/thankYou')
                }else{
                    emptyCart()
                }

            }

        }, [nav,isCartMenuOpen, setIsCartMenuOpen, currentHeighMenu, setCurrentHeighMenu]
    )

    const closer = useCallback(
    () => {
        let BuyBlock = document.getElementById("buy_block_con")
                BuyBlock.style.height = '11.2vh';


                let CurrentMenu = document.getElementById("cart_menu")

                CurrentMenu.style.height = `${currentHeighMenu}px`

                let buy_inputs = document.getElementById("buy_inputs")
                buy_inputs.style.height = '0'
                buy_inputs.style.margin = '0'
                buy_inputs.style.overflow = 'hidden'

                document.getElementById("buy_button").style.color = "white"
                BuyBlock.style.background = 'none'
                document.getElementById("close_btn_buy").style.transform = "rotate(0deg)"
                document.getElementById("close_btn_buy").style.opacity= "0"
                
                setIsCartMenuOpen(false)
    }
        ,[isCartMenuOpen, setIsCartMenuOpen, currentHeighMenu, setCurrentHeighMenu])

    return (
        <form id="buy_block_con" className="buy_block">
            <div id='buy_inputs' className='inputs_buy'>
                <div className='inputs_buy_form'>
                    <input value={user_name} onChange={nameKeeper} placeholder='memory' title="Введіть ім'я" id='name_buy' name='name_buy' className='buy_inputs' type="text" required />
                    <label htmlFor="name_buy" className='buy_label'>Ім'я</label>
                </div>
                <div className='inputs_buy_form'>
                    <input value={user_phone} onChange={phoneKeeper} placeholder='memory' title="Введіть ваш номер" id='phone_buy' name='phone_buy' className='buy_inputs' type="text" required />
                    <label htmlFor="phone_buy" className='buy_label'>Ваш номер</label>
                </div>
                

            </div>
            <img onClick={closer} id='close_btn_buy'className='back_btnsvg' src="./img/back_btn.svg" alt="Закрити" title='Закрити' />
            <div className="btn_buy_cart_con">
                <div className='current_price_block_con'>
                    <p id='buy_all_detail' className="current_price_detail">Детальні ціни уточняйте під час підтвердження замовлення</p>

                </div>
                

                <div onClick={formOpener} id="buy_button" className="current_buy_btn">Придбати</div>
            </div>
            <div id='empty_cart_menu' className="modal_error_cart_empty_con">
                <div className="modal_error_cart_empty">
                    <div className="modal_error_cart_empty_title">Корзина пуста</div>
                    <div className="modal_error_cart_empty_paragraph">Ви можете додати товари на сторінці вибору товарів</div>
                    <div className="modal_error_cart_empty_paragraph">Або зв'язатися за консультацією чи оформленням замовлення за номерами:</div>
                    <a href="tel:+380-67-676-03-64"><div className='numbers_error'>+380-67-676-03-64</div></a>
                    <a href="tel:+380-98-249-60-50"><div className='numbers_error'>+380-98-249-60-50</div></a>
                    <div className="modal_error_cart_empty_paragraph">Також перевірте правильність заповненння полів "Ім'я" та "Ваш номер"</div>


                </div>
            </div>
        </form>
        
    )
}

