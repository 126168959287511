import React , { useState,useEffect} from 'react';
import './dist/navbar.css';
import { NavLink , useNavigate } from 'react-router-dom';

function Nav({cart}){
    const nav = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
    const [currentCount,setCurrentCount] = useState(0)


    const menunavigatorphone = () => {
        if(isOpen === false){
            document.getElementById("phone_nav_full").style.height = '10vh'
            document.getElementById("s_line_b").style.width = "100%"
            document.getElementById("s_line_b").style.left = "-.2vh"
            document.getElementById("t_line_b").style.width = "100%"
            document.getElementById("t_line_b").style.left = ".1vh"
            setTimeout(() => {
                document.getElementById("f_line_b").style.transform = "rotate(45deg)"
                document.getElementById("f_line_b").style.top = "1vh"
    
                document.getElementById("t_line_b").style.transform = "rotate(-45deg)"
                document.getElementById("t_line_b").style.top = "1vh"
                document.getElementById("s_line_b").style.opacity = "0"
            }, 100);

           
            setIsOpen(true)
        }
        else{
            document.getElementById("s_line_b").style.opacity = "1"

            document.getElementById("f_line_b").style.transform = "rotate(0deg)"
            document.getElementById("f_line_b").style.top = "0"
    
            document.getElementById("t_line_b").style.transform = "rotate(0deg)"
            document.getElementById("t_line_b").style.top = "2vh"
            
            setTimeout(() => {
                document.getElementById("s_line_b").style.width = "67%"
                document.getElementById("s_line_b").style.left = "29%"
    
    
                document.getElementById("t_line_b").style.width = "60%"
                document.getElementById("t_line_b").style.left = "0"
            }, 100);


            
            document.getElementById("phone_nav_full").style.height = '0'
            setIsOpen(false)
        
        }

        console.log(isOpen)
    }
    const navigationToServ =
        (e) => {
            e.preventDefault()
            if(window.location.pathname === '/'){
                let serv_bloc = document.getElementById("serv_block")
                serv_bloc.scrollIntoView({ behavior: 'smooth' });
                menunavigatorphone()
            }
            else{
                nav('/')
                menunavigatorphone()
                setTimeout(()=>{
                    let serv_bloc = document.getElementById("serv_block")
                    serv_bloc.scrollIntoView({ behavior: 'smooth' });
                }, 200)
            }
        }

    const cartActivity = () => {
        if(isActive === true){
            document.getElementById('cart_img').src = process.env.PUBLIC_URL + '/img/carthover.svg'
        }else{
            document.getElementById('cart_img').src = process.env.PUBLIC_URL + '/img/shopping_cart1.svg'
        }
        
    }
   useEffect(()=>{
    setCurrentCount(cart.length)
    if(cart.length === 0){  
        document.getElementById("circle_counter").style.opacity = 0
    }else{
        document.getElementById("circle_counter").style.opacity = 1
    }
        
   },[cart.length])


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
        });
      };

    return(
        <header id='navbar' className='navbar'>
                    <NavLink to = "/" ><div className="left_part"  ><p className='title_left'>Реквієм</p></div> </NavLink>   
                    <div className="right_part">
                        <div className="links_con">
                            <NavLink onClick = {scrollToTop} to='/select_category' className= "links dropdown" activeclassname="active">Ритуальні товари</NavLink> 
                            <NavLink onClick = {scrollToTop} to='/contacts' className= "links" activeclassname="active">Контакти</NavLink>  
                            <div className="category_menu">
                                <NavLink onClick = {scrollToTop} to="lampscandles" className="c_links">лампадки та свічки</NavLink>
                                <NavLink onClick = {scrollToTop} to="veils" className="c_links">покривала</NavLink>
                                <NavLink onClick = {scrollToTop} to="coffin" className="c_links">домовини</NavLink>
                                <NavLink onClick = {scrollToTop} to="crosses" className="c_links">хрести</NavLink>
                                <NavLink onClick = {scrollToTop} to="wreaths" className="c_links">вінки</NavLink>
                                <NavLink onClick = {scrollToTop} to="clothes" className="c_links">одяг та взуття</NavLink>
                            </div>   
                        </div>
                        <NavLink  to='/cart'>
                            <div id='circle_counter' className="circle_counter">{currentCount}</div>
                            <img onMouseEnter={() => {setIsActive(false); cartActivity();}} onMouseLeave={() => {setIsActive(true); cartActivity();}} id='cart_img' className='cart' src={process.env.PUBLIC_URL + '/img/shopping_cart1.svg'} alt="cart" title = 'cart' />
                        </NavLink>
                    </div>
                    <div className='burger'>
                        <NavLink to='/cart'><img src={process.env.PUBLIC_URL + "/img/shopping_cart1.svg"} alt="cart" title = "cart" /></NavLink>
                        <div onClick={menunavigatorphone} className='burger_menu'>
                            <div id = "f_line_b" className='eblyasanimation'></div>
                            <div id = 's_line_b' className='eblyasanimation'></div>
                            <div id = 't_line_b' className='eblyasanimation'></div>
                        </div>
                    </div>
                    <div id="phone_nav_full" className="phone_nav">
                        <div className="phone_page_nav">
                            <NavLink to='/select_category' onClick={menunavigatorphone} className='phone_page_nav_e'>Товари</NavLink>
                            <NavLink to='/contacts' onClick={menunavigatorphone} className='phone_page_nav_e'>Контакти</NavLink>
                        </div>
                        
            
                        
                    </div>
                    
            </header>
    )
}

export default Nav;