import React from 'react'
import { NavLink } from 'react-router-dom'
import "./dist/crossescard.css"

import getMethods from '../../services/getMethods'



export class CrosseCard extends React.Component {
    constructor(props){
        super(props)
        this.createObject = this.createObject.bind(this)
        this.waitCoffImg = this.waitCoffImg.bind(this)



        this.state = {
            currentObject : {
                id: '',
                name : '',
                material:'',
                cross_coating:'',
                price:'',
                img: null,
                id: ''

            }
        }

    }


    async waitCoffImg (){
        const response = await getMethods.getCoffImg("cross",this.props.cross.id)

        if(response){
            this.setState({
                img: response
            })
        }

        return response
    }



    updateFieldValue = (key, value) => {
        this.setState(prevState => ({
            currentObject: {
            ...prevState.currentObject,
            [key]: value
          }
        }));
      }

    createObject(id,name ,material,cross_coating,price,img){
        this.updateFieldValue('id', id)
        this.updateFieldValue('name', name)
        this.updateFieldValue('material', material)
        this.updateFieldValue('cross_coating', cross_coating)
        this.updateFieldValue('price', price)
        this.updateFieldValue('img', img)

    }


    modalViewer = (local_text) => {
        document.getElementById('modal_w').style.transform = 'translatey(0vh)'
        document.getElementById('modal_w').innerText = local_text


        setTimeout(()=> {
            if( document.getElementById('modal_w'))
                document.getElementById('modal_w').style.transform = 'translatey(11vh)'
        }, 2000)
    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }
    id_cheker = () =>{
        const objectExists = this.props.cart.some(item => item.id === this.props.cross.id);
        console.log(this.props.cart)
        console.log()
        return objectExists

    }

    addToCartLocal = async (e)=>{
        e.preventDefault();
        await this.createObject(
                    this.props.cross.id ? this.props.cross.id:'',
                    this.props.cross.name ? this.props.cross.name:'',
                    this.props.cross.material? this.props.cross.material:'',
                    this.props.cross.cross_coating?this.props.cross.cross_coating:'',
                    this.props.cross.price?this.props.cross.price:'',
                    this.state.img[0].image?this.state.img[0].image:'',


                    )
        this.props.addToCart(this.state.currentObject)
        this.soundBoard()
        if(!this.id_cheker()){
            this.modalViewer("Товар додано у корзину")
        }else{
            this.modalViewer("Повторно додано у корзину ")
            
        }



    }


    async componentDidMount(){
        await this.waitCoffImg()

        this.setState({id: this.props.cross.id})


        this.props.setLink('cross')

    }



    render(){
        return(
            <NavLink to={`/product/cross/${this.state.id}`} className="products_card">
                <img className='p_img' src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt={this.props.crosses.name} title={this.props.crosses.name} />

                <div className="p_card_title">{this.props.cross.name ? this.props.cross.name:''}</div>
                <div className="category">
                    <p className='m_title'>матеріал</p>
                    <p className='c_body'>{this.props.cross.material? this.props.cross.material:''}</p>
                </div>
                <div className="category">
                    <p className='m_title'>покриття</p>
                    <p className='c_body'>{this.props.cross.cross_coating?this.props.cross.cross_coating:''}</p>
                </div>
                <div onClick={this.addToCartLocal} className="buy_p_btn">Придбати</div>
            </NavLink>
        )
    }
}