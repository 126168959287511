import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { ProductCard } from "../../components/ProductCard/productcard";
import "./dist/LiveWreathsPage.css"

export class LiveWreathsPage extends React.Component {
    render(){
        return(
            <div className="products_page">
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="" />
                </header>
                <p className="product_view_page_title">Живі вінки</p>
                <div className="product_view_page">
                    
                    <div className="products_view">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>

                    </div>
                </div>
                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}