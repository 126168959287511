import React,{useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export function AdminCheker(){
    const nav = useNavigate()
    
    const getCookie = (name)=> {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');
      
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].split('=');
          if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
          }
        }
      
        return null;
    }

    const adminCheker = ()=>{
        const token = getCookie('token');

        if (token) {
            return 0
        } else {
            nav('/login')
        }
    }

    useEffect(()=>{
        adminCheker()
    },[])

    return(
        <div>

        </div>
    )
}
