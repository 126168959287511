import React from "react";

import "./dist/cartCard.css"

export class CartCard extends React.Component {
    constructor(props){
        super(props)
    }

    removeFromCartLocal = (e) =>{
        e.preventDefault();
        this.props.removeFromCart(this.props.fullData.length-1-this.props.index)
    }
  

    render() {
        return(
            <div className="cart_card">
                <div className = 'card_img_con'>
                    <img className ="card_img" src={'https://requiem.com.ua' + (this.props.data?.img?this.props.data?.img:"")} alt="card-image" title="card-image" />

                </div>
                <div className="card_info_con">
                    <span className="card_title">{this.props.data.name?this.props.data.name:"Йде завантаження"}</span>
                    <div className="category_punkts">
                        <div className="left_c_part">
                        {Object.keys(this.props.data).length === 8 && this.props.data.flower_type && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал каркасу</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">Квіти</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">розмір</p>
                                </div>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 8 && this.props.data.internal_decoration && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">покриття</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">внутрішня тканина</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">розмір</p>
                                </div>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 5 && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал</p>
                                </div>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 7  && this.props.data.blanket_type && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">тип</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">декорації</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">розмір</p>
                                </div>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 7  && this.props.data.description && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">опис</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">розмір</p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал</p>
                                </div>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 6  && (
                            <React.Fragment>
                                <div className="card_category">
                                    <p className="card_category_title">матеріал </p>
                                </div>
                                <div className="card_category">
                                    <p className="card_category_title">покриття</p>
                                </div>
                            </React.Fragment>
                        )}
                        
                        
                        </div>
                        <div className="right_c_part">
                        {Object.keys(this.props.data).length === 8 && this.props.data.internal_decoration && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.material?this.props.data.material:"..."}</p>
                                <p className="card_category_body">{this.props.data.coating?this.props.data.coating:"..."}</p>
                                <p className="card_category_body">{this.props.data.internal_decoration?this.props.data.internal_decoration:"..."}</p>
                                <p className="card_category_body">{this.props.data.size?this.props.data.size:"..."}</p>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 8 && this.props.data.flower_type && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.material?this.props.data.material:"..."}</p>
                                <p className="card_category_body">{this.props.data.frame_material?this.props.data.frame_material:"..."}</p>
                                <p className="card_category_body">{this.props.data.flower_type?this.props.data.flower_type:"..."}</p>
                                <p className="card_category_body">{this.props.data.size?this.props.data.size:"..."}</p>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 5 && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.candle_type?this.props.data.candle_type:"..."}</p>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 7  && this.props.data.blanket_type && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.blanket_type?this.props.data.blanket_type:"..."}</p>
                                <p className="card_category_body">{this.props.data.blanket_decoration?this.props.data.blanket_decoration:"..."}</p>
                                <p className="card_category_body">{this.props.data.blanket_size?this.props.data.blanket_size:"..."}</p>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 7  && this.props.data.description && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.description?this.props.data.description:"..."}</p>
                                <p className="card_category_body">{this.props.data.size?this.props.data.size:"..."}</p>
                                <p className="card_category_body">{this.props.data.material?this.props.data.material:"..."}</p>
                            </React.Fragment>
                        )}
                        {Object.keys(this.props.data).length === 6  && (
                            <React.Fragment>
                                <p className="card_category_body">{this.props.data.material?this.props.data.material:"..."}</p>
                                <p className="card_category_body">{this.props.data.cross_coating?this.props.data.cross_coating:"..."}</p>
                            </React.Fragment>
                        )}
                        
                            
                        </div>
                    </div>
                </div>
                <img onClick={this.removeFromCartLocal} className="cancel_btn" src="./img/gcancel.svg" alt="Картинка корзини" title="Картинка корзини" />

            </div>
        )
    }

}