import React from 'react'
import { NavLink } from 'react-router-dom'
import "./dist/lampcard.css"

import getMethods from '../../services/getMethods'






export class LampCard extends React.Component {
    constructor(props){
        super(props)
        this.createObject = this.createObject.bind(this)
        this.waitCoffImg = this.waitCoffImg.bind(this)


        this.state = {
            currentObject : {
                id:'',
                name : '',
                candle_type:'',
                price:'',
                img: null,

            },
            id_l: ''
        }

    }


    async waitCoffImg (){
        const response = await getMethods.getCoffImg("candles",this.props.candel.id)

        if(response){
            this.setState({
                img: response
            })
        }

        return response
    }


    updateFieldValue = (key, value) => {
        this.setState(prevState => ({
            currentObject: {
            ...prevState.currentObject,
            [key]: value
          }
        }));
      }

    createObject(id,name,candle_type,img,price){
        this.updateFieldValue('id', id)
        this.updateFieldValue('name', name)
        this.updateFieldValue('candle_type', candle_type)
        this.updateFieldValue('img', img)
        this.updateFieldValue('price', price)

    }


    modalViewer = (local_text) => {
        document.getElementById('modal_w').style.transform = 'translatey(0vh)'
        document.getElementById('modal_w').innerText = local_text

        setTimeout(()=> {
            if( document.getElementById('modal_w'))
                document.getElementById('modal_w').style.transform = 'translatey(11vh)'
        }, 2000)
    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }
    id_cheker = () =>{
        const objectExists = this.props.cart.some(item => item.id === this.props.candel.id);
        return objectExists

    }
    

    addToCartLocal = async (e)=>{
        e.preventDefault();
        await this.createObject(
            this.props.candel.id ? this.props.candel.id:'',
            this.props.candel.name ? this.props.candel.name:'',
            this.props.candel.candle_type? this.props.candel.candle_type:'',
            this.state.img[0].image?this.state.img[0].image:'',
            this.props.candel.price?this.props.candel.price:'',
        )
        this.props.addToCart(this.state.currentObject)
        this.soundBoard()
        if(!this.id_cheker()){
            this.modalViewer("Товар додано у корзину")
        }else{
            this.modalViewer("Повторно додано у корзину ")
            
        }



    }



    async componentDidMount(){
        await this.waitCoffImg()
        this.setState({id_l: this.props.candel.id})


        this.props.setLink('lamp')

    }



    render(){
        return(
            <NavLink to={`/product/lamp/${this.state.id_l}`} className="products_card">
                <img className='p_img' src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt={this.props.candel.name} title = {this.props.candel.name} />

                <h3 className="p_card_title">{this.props.candel.name ? this.props.candel.name:''}</h3>
                <div className="category">
                    <p className='m_title'>тип</p>
                    <h4 className='c_body'>{ this.props.candel.candle_type? this.props.candel.candle_type:''}</h4>
                </div>
                <div onClick={this.addToCartLocal} className="buy_p_btn">Придбати</div>
            </NavLink>
        )
    }
}