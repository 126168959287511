import React from 'react';

import { HeaderMain } from '../../components/HeaderMain/headermain';
import { Services } from '../../components/Services/services';
import { FooterUp } from '../../components/FooterUp/footerUp';
import './dist/main.css';
import { FooterBot } from '../../components/FooterBot/footerbot';
import { NavLink } from 'react-router-dom';

import getMethods from '../../services/getMethods';
import { CarouselCard } from '../../components/Carousel_card/Carousel_card';
import { SCarouselCard } from '../../components/Carousel_card/SCarousel_Card';




export class Main extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            coffindata:null,
            clothesdata:null,
            wreathdata:null,
            blankeddata:null,
            lampcanddata:null,
            crossesdata:null,
            
            scoffindata:null,
            sclothesdata:null,
            swreathdata:null,
            sblankeddata:null,
            slampcanddata:null,
            scrossesdata:null,

            sFOpener: false,
            sSOpener: false,
            punkt: 'вінки',
            redirect: '/wreaths',
            sredirect: '/coffin',
            spunkt: 'домовини',

            fproducts: null,
            sproducts: null,
            fimg: '',
            typeChanged: 'wreath',
            stypeChanged: 'coffin'

        }

        this.selectFMenuOpener = this.selectFMenuOpener.bind(this);
        this.selectSMenuOpener = this.selectSMenuOpener.bind(this);
        this.aboutScroll = this.aboutScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.selectPunktL = this.selectPunktL.bind(this)
        this.selectPunktB = this.selectPunktB.bind(this)
        this.selectPunktC = this.selectPunktC.bind(this)
        this.selectPunktH = this.selectPunktH.bind(this)
        this.selectPunktV = this.selectPunktV.bind(this)
        this.selectPunktW = this.selectPunktW.bind(this)
        this.selectsPunktL = this.selectsPunktL.bind(this)
        this.selectsPunktB = this.selectsPunktB.bind(this)
        this.selectsPunktC = this.selectsPunktC.bind(this)
        this.selectsPunktH = this.selectsPunktH.bind(this)
        this.selectsPunktV = this.selectsPunktV.bind(this)
        this.selectsPunktW = this.selectsPunktW.bind(this)
        this.hideArrow = this.hideArrow.bind(this)

        // get methods
        this.waitWreaths = this.waitWreaths.bind(this)
        this.waitCandles = this.waitCandles.bind(this)
        this.waitCoffin = this.waitCoffin.bind(this)
        this.waitBlankets = this.waitBlankets.bind(this)
        this.waitCrosses = this.waitCrosses.bind(this)
        this.waitClothes = this.waitClothes.bind(this)
        
        this.swaitWreaths = this.swaitWreaths.bind(this)
        this.swaitCandles = this.swaitCandles.bind(this)
        this.swaitCoffin = this.swaitCoffin.bind(this)
        this.swaitBlankets = this.swaitBlankets.bind(this)
        this.swaitCrosses = this.swaitCrosses.bind(this)
        this.swaitClothes = this.swaitClothes.bind(this)


    }


    

    async waitWreaths(){
        
        if(this.state.wreathdata === null){
            const response = await getMethods.getWreaths()
            if(response){
                this.setState({
                    wreathdata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.wreathdata,
                      })
                })
              }

            return response
        }
        if(this.state.wreathdata){
          this.setState({
            fproducts: this.state.wreathdata,
          })
        }

    }

    async waitCandles(){
        if(this.state.lampcanddata === null){
            const response = await getMethods.getCandles()
            if(response){
                this.setState({
                    lampcanddata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.lampcanddata,
                      })
                })
              }

            return response
        }
        if(this.state.lampcanddata){
          this.setState({
            fproducts: this.state.lampcanddata,
          })
        }
    }

    async waitCoffin() {
        if(this.state.coffindata === null){
            const response = await getMethods.getProducts()
            if(response){
                this.setState({
                    coffindata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.coffindata,
                      })
                })
              }

            return response
        }
        if(this.state.coffindata){
          this.setState({
            fproducts: this.state.coffindata,
          })
        }
    }

    async waitBlankets(){
        if(this.state.blankeddata === null){
            const response = await getMethods.getBlankets()
            if(response){
                this.setState({
                    blankeddata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.blankeddata,
                      })
                })
              }

            return response
        }
        if(this.state.blankeddata){
          this.setState({
            fproducts: this.state.blankeddata,
          })
        }
    }

    async waitCrosses(){
        if(this.state.crossesdata === null){
            const response = await getMethods.getCrosses()
            if(response){
                this.setState({
                    crossesdata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.crossesdata,
                      })
                })
              }

            return response
        }
        if(this.state.crossesdata){
          this.setState({
            fproducts: this.state.crossesdata,
          })
        }
       
    }

    async waitClothes(){
        if(this.state.clothesdata === null){
            const response = await getMethods.getClothes()
            if(response){
                this.setState({
                    clothesdata: response,
                },()=>{
                    this.setState({
                        fproducts: this.state.clothesdata,
                      })
                })
              }

            return response
        }
        if(this.state.clothesdata){
          this.setState({
            fproducts: this.state.clothesdata,
          })
        }
    }


    async swaitWreaths(){
        
        if(this.state.swreathdata === null){
            const response = await getMethods.getWreaths()
            if(response){
                this.setState({
                    swreathdata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.swreathdata,
                      })
                })
              }

            return response
        }
        if(this.state.swreathdata){
          this.setState({
            sproducts: this.state.swreathdata,
          })
        }

    }

    async swaitCandles(){
        if(this.state.slampcanddata === null){
            const response = await getMethods.getCandles()
            if(response){
                this.setState({
                    slampcanddata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.slampcanddata,
                      })
                })
              }

            return response
        }
        if(this.state.slampcanddata){
          this.setState({
            sproducts: this.state.slampcanddata,
          })
        }
    }

    async swaitCoffin() {
        if(this.state.scoffindata === null){
            const response = await getMethods.getProducts()
            if(response){
                this.setState({
                    scoffindata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.scoffindata,
                      })
                })
              }

            return response
        }
        if(this.state.scoffindata){
          this.setState({
            sproducts: this.state.scoffindata,
          })
        }
    }

    async swaitBlankets(){
        if(this.state.sblankeddata === null){
            const response = await getMethods.getBlankets()
            if(response){
                this.setState({
                    sblankeddata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.sblankeddata,
                      })
                })
              }

            return response
        }
        if(this.state.sblankeddata){
          this.setState({
            sproducts: this.state.sblankeddata,
          })
        }
    }

    async swaitCrosses(){
        if(this.state.scrossesdata === null){
            const response = await getMethods.getCrosses()
            if(response){
                this.setState({
                    scrossesdata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.scrossesdata,
                      })
                })
              }

            return response
        }
        if(this.state.scrossesdata){
          this.setState({
            sproducts: this.state.scrossesdata,
          })
        }
       
    }

    async swaitClothes(){
        if(this.state.sclothesdata === null){
            const response = await getMethods.getClothes()
            if(response){
                this.setState({
                    sclothesdata: response,
                },()=>{
                    this.setState({
                        sproducts: this.state.sclothesdata,
                      })
                })
              }

            return response
        }
        if(this.state.sclothesdata){
          this.setState({
            sproducts: this.state.sclothesdata,
          })
        }
    }





    scrollToTop () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    async selectPunktL(){
        try {
            await this.waitCandles()
            let punkt = document.getElementById('recent_punkt')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"

            this.setState({ sFOpener: false })
            this.setState({redirect: '/lampscandles'})
            this.setState({typeChanged: 'candles'})
        } catch (error) {
            let punkt = document.getElementById('recent_punkt')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"

            this.setState({ sFOpener: false })
            this.setState({redirect: '/lampscandles'})
            this.setState({typeChanged: 'candles'})
        }
        

    }

    async selectPunktB(){
        try {
            await this.waitBlankets()
            let punkt = document.getElementById('recent_punktb')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/veils'})
    
            this.setState({typeChanged: 'blanket'})
    
        } catch (error) {
            let punkt = document.getElementById('recent_punktb')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/veils'})
    
            this.setState({typeChanged: 'blanket'})
    
        }

    }

    async selectPunktC(){
        try {
            await this.waitCoffin()
            let punkt = document.getElementById('recent_punktc')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/coffin'})
            this.setState({typeChanged: 'coffin'})
        } catch (error) {
            let punkt = document.getElementById('recent_punktc')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/coffin'})
            this.setState({typeChanged: 'coffin'})
        }


    }

    async selectPunktH(){
        try {
            await this.waitCrosses()
            let punkt = document.getElementById('recent_punkth')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/crosses'})
            this.setState({typeChanged: 'cross'})
        } catch (error) {
            let punkt = document.getElementById('recent_punkth')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/crosses'})
            this.setState({typeChanged: 'cross'})
        }


    }

    async selectPunktV(){
        try {
            await this.waitWreaths()
            let punkt = document.getElementById('recent_punktv')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/wreaths'})
    
            this.setState({typeChanged: 'wreath'})
        } catch (error) {
            let punkt = document.getElementById('recent_punktv')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/wreaths'})
    
            this.setState({typeChanged: 'wreath'})
        }


    }

    

    async selectPunktW(){
        try {
            await this.waitClothes()
            let punkt = document.getElementById('recent_punktw')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/clothes'})
    
            this.setState({typeChanged: 'clothandshoes'})
        } catch (error) {
            let punkt = document.getElementById('recent_punktw')
            this.setState({punkt: punkt.innerText})
            document.getElementById('recent_dropdown').style.height = '0vw'
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sFOpener: false })
            this.setState({redirect: '/clothes'})
    
            this.setState({typeChanged: 'clothandshoes'})
        }


    }

    async selectsPunktL(){
        try {
            await this.swaitCandles()
            let punkt = document.getElementById('srecent_punkt')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/lampscandles'})
            this.setState({stypeChanged: 'candles'})
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punkt')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/lampscandles'})
            this.setState({stypeChanged: 'candles'})
        }
        
    }

    async selectsPunktB(){
        try {
            await this.swaitBlankets()
            let punkt = document.getElementById('srecent_punktb')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sSOpener: false })
            this.setState({sredirect: '/veils'})
            this.setState({stypeChanged: 'blanket' })
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punktb')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"
    
            this.setState({ sSOpener: false })
            this.setState({sredirect: '/veils'})
            this.setState({stypeChanged: 'blanket' })
        }
      
    }

    async selectsPunktC(){
        try {
            await this.swaitCoffin()
            let punkt = document.getElementById('srecent_punktc')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/coffin'})
            this.setState({stypeChanged:'coffin'})
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punktc')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/coffin'})
            this.setState({stypeChanged:'coffin'})
            
        }
        
    }

    async selectsPunktH(){
    
        try {
            await this.swaitCrosses()
            let punkt = document.getElementById('srecent_punkth')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/crosses'})
            this.setState({stypeChanged: 'cross'})
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punkth')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/crosses'})
            this.setState({stypeChanged: 'cross'})
            
        }
        
    }

    async selectsPunktV(){
        try {
            await this.swaitWreaths()
            let punkt = document.getElementById('srecent_punktv')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/wreaths'})
            this.setState({stypeChanged: 'wreath'})
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punktv')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/wreaths'})
            this.setState({stypeChanged: 'wreath'})
            
        }
        
    }


    async selectsPunktW(){
        try {
            await this.swaitClothes()
            let punkt = document.getElementById('srecent_punktw')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/clothes'})
            this.setState({stypeChanged: 'clothandshoes'})
            
        } catch (error) {
            let punkt = document.getElementById('srecent_punktw')
            this.setState({spunkt: punkt.innerText})
            document.getElementById('srecent_dropdown').style.height = '0vw'
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"

            this.setState({ sSOpener: false })
            this.setState({sredirect: '/clothes'})
            this.setState({stypeChanged: 'clothandshoes'})
        }
        
    }
    selectFMenuOpener() {
        if (this.state.sFOpener === false) {
            document.getElementById("s_arrow").style.transform = "rotate(0deg)"
            document.getElementById('recent_dropdown').style.height = '40vh'
            
            this.setState({ sFOpener: true })
        }
        else {
            document.getElementById("s_arrow").style.transform = "rotate(-90deg)"
            document.getElementById('recent_dropdown').style.height = '0vw'
            
            this.setState({ sFOpener: false })
        }

    }
    selectSMenuOpener() {
        if (this.state.sSOpener === false) {
            document.getElementById("s_sarrow").style.transform = "rotate(0deg)"
            document.getElementById('srecent_dropdown').style.height = '40vh'
            this.setState({ sSOpener: true })
        }
        else {
            document.getElementById("s_sarrow").style.transform = "rotate(-90deg)"
            document.getElementById('srecent_dropdown').style.height = '0vh'
            this.setState({ sSOpener: false })
        }
    }

    aboutScroll() {
        if (window.location.pathname === '/' && window.innerWidth > 426) {
            let aboutBlock = document.getElementById("about_us").style
            if (window.scrollY > 400 && window.scrollY < 1380 && window.innerHeight > 720 && window.innerHeight < 1000) {
                aboutBlock.opacity = "1"
                aboutBlock.margin = "11vh 0 0 0 "
            }
            else if (window.scrollY > 200 && window.scrollY < 1640 && window.innerHeight < 700 && window.innerHeight > 480) {
                aboutBlock.opacity = "1"
                aboutBlock.margin = "11vh 0 0 0 "

            }
            else {
                aboutBlock.opacity = "0"
            }
        }
        else {
            if (window.location.pathname === '/') {
                let aboutBlock = document.getElementById("about_us").style
                if (window.scrollY > 0 && window.scrollY < 1707) {
                    aboutBlock.marginTop = "6.4vh"
                    aboutBlock.opacity = "1"
                }
                else {
                    aboutBlock.marginTop = "0"
                    aboutBlock.opacity = "0"
                }

            }
        }
    }

    hideArrow(){
        if(window.location.pathname === '/'){
            let toTopArr = document.getElementById("toTopArrow")
            if(window.scrollY < 400 ){
                toTopArr.style.opacity = '0'
            }else{
                toTopArr.style.opacity = '100'
            }
        }
        

    }

    async componentDidMount() {
        try {
            await this.waitWreaths() 
            await this.swaitCoffin()

            window.addEventListener("scroll",this.hideArrow)        
            window.addEventListener('scroll', this.aboutScroll);
            
        } catch (error) {
            window.addEventListener("scroll",this.hideArrow)        
            window.addEventListener('scroll', this.aboutScroll);
        }
        





    }

    render() {
        return (
            <main className='main_block'>

                


                <HeaderMain phoneBack={this.props.phoneBack} nameBack={this.props.nameBack} wait_send_or_call = {this.props.wait_send_or_call} />
                <p className="about_us">Про нас</p>
                <div id='about_us' className="about__us">
                    <div className="about_us_text_con">
                        <div className='about_us_text'>
                            <div className='main_con_h2'>
                                <h2 className='aub_us_h2'>Магазин ритуальних послуг Реквієм<nobr/> у Львові! <br/></h2>
                                Наш магазин Реквієм присвячений наданню підтримки у найважчі миті життя – під час втрати близької людини. 
                                Ми розуміємо, що <h3 className='aub_us_h3'>організація похоронних заходів</h3> та вираз відповідної поваги і гідності вимагають спеціальної уваги і дбайливості.
                                Ми вдячні вам за довіру, яку ви виявляєте, обираючи нас у цей нелегкий час, і зобов'язані зробити все можливе, щоб допомогти вам в цьому процесі.
                            </div>
                        </div>
                    </div>
                    <div className="photo_con">
                        <div className="about_us_photo">
                            <img className='about_us_img' src="./img/main_first_photo.jpg" alt="Магазин ритуальних послуг Реквієм" title='Магазин ритуальних послуг Реквієм'/>
                        </div>
                        <div id="about_us_photo2" className="about_us_photo">
                            <img className='about_us_img' src="./img/main_second_photo.jpg" alt="Магазин ритуальних послуг Реквієм" title='Магазин ритуальних послуг Реквієм'/>
                        </div>
                    </div>
                </div>
                <div className='more_text_con'>
                    <p className='help_text'>У магазині <h2 className='aub_us_h2_r'><b>Реквієм </b></h2>ми готові допомогти вам кожним кроком цього важкого шляху:</p>
                    <div className="instruction_con">
                        <div className="instruction">
                            <h3 className='instruction_title'>✵ Індивідуальний Підхід:</h3>
                            <p className='instruction_text'>Наші професіонали розуміють, що кожна родина має свої унікальні потреби та побажання. Ми готові надати консультації та підтримку, щоб допомогти вам вибрати послуги, які найкраще відповідають вашим потребам.</p>
                        </div>
                        <div className="instruction">
                            <h3 className='instruction_title'>✵ Широкий Вибір Товарів:</h3>
                            <p className='instruction_text'>Наш асортимент включає вінки зі живих та штучних квітів, труни різних дизайнів,розмірів та матеріалів, ритуальні хрести та ритуальний одяг відповідно до ваших побажань.</p>
                        </div>
                        <div className="instruction">
                            <h3 className='instruction_title'>✵ Підтримка та Послуги:</h3>
                            <p className='instruction_text'>Наша команда готова забезпечити вас всіма необхідними послугами – від оформлення документів до організації ритуальної церемонії. Ми дбаємо про кожну деталь, щоб ви могли надати вашій близькій людині гідний прощальний акт.</p>
                        </div>
                    </div>
                </div>
                {/* <Services />  */}
                <div className='title_posl'>Ми хочемо вам запропонувати Вам такі послуги:</div>
                <div className='poslygy_con'>
                    <div className='left-part'>
                        <div className='item-left'>
                            <img className='manImg' src="./img/morg.svg" alt='Транспортування тіла покійного в морг' title='Транспортування тіла покійного в морг'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Транспортування тіла покійного в морг</h2>
                                <p>Важливий етап, який включає дбайливе та відповідне транспортування тіла покійного до моргу. Це забезпечує, щоб тіло було збережено і підготовлено для подальших процедур.</p>
                            </div>
                        </div>
                        <div className='item-left_t'>
                            <img className='manImg' src="./img/man.svg" alt='Послуги вантажників' title='Послуги вантажників'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Послуги вантажників</h2>
                                <p>Професіональні вантажники забезпечують обережне переміщення тіла з моргу до автомобіля або катафалка. Це гарантує безпечну обробку покійного під час переміщення.</p>
                            </div>

                        </div>
                        <div className='item-left_th'>
                            <img className='manImg' src="./img/document.svg" alt='Оформлення свідоцтва про смерть' title='Оформлення свідоцтва про смерть'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Оформлення свідоцтва про смерть</h2>
                                <p>Доцільно оформити всі необхідні документи для офіційної реєстрації смерті. Це включає підготовку свідоцтва про смерть та інших адміністративних формальностей.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className='right-part'>
                        <div className='item-right'>
                            <img className='manImg' src="./img/organization.svg" alt='Організація поховання' title='Організація поховання'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Організація поховання</h2>
                                <p>Це комплексний процес, який включає встановлення похованої людини у могилу або кремацію відповідно до вибору родини та покійного. Подія може бути супроводжена релігійною церемонією.</p>
                            </div>
                        </div>
                        <div className='item-right_t'>
                            <img className='manImg' src="./img/car.svg" alt='Катафалки різного класу та цінової категорії' title='Катафалки різного класу та цінової категорії'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Катафалки різного класу та цінової категорії</h2>
                                <p>Магазин пропонує широкий вибір катафалків – спеціальних автомобілів для перевезення тіла. Вони доступні різних класів та цінових категорій, дозволяючи підібрати оптимальний варіант залежно від ваших потреб.</p>
                            </div>
                        </div>
                        <div className='item-right_th'>
                            <img className='manImg' src="./img/church.svg" alt='Організація каплиці і церкви' title='Організація каплиці і церкви'/>
                            <div className='textBlock'>
                                <h2 className='title_item_l'>Організація каплиці і церкви</h2>
                                <p>Якщо сім'я покійного бажає провести релігійну церемонію, магазин допомагає організувати каплицю або церкву для прощальної служби. Це включає прикладання всіх необхідних зусиль для забезпечення гідної та пам'ятної церемонії.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='end_posl'>Кожен з цих пунктів є важливим кроком у похоронному процесі та сприяє відзначенню життя покійної людини з повагою та гідністю. У магазині Реквієм ми працюємо, щоб забезпечити професійну підтримку та допомогу на кожному етапі.</div>
                <div className="last_product">
                    <div className='recent_produts' id='recent_produts'>
                        <p className='recent_produts_text'>Останні товари у категорії </p>
                        <div onClick={this.selectFMenuOpener} name="" id="" className='select'>
                            {this.state.punkt}
                            <img id="s_arrow" src="/img/polygon.svg" alt="polygon" title='polygon'/>
                        </div>
                        <div id='recent_dropdown' className='recent_dropdown'>
                                <p onClick={this.selectPunktL} id = 'recent_punkt' className="recent_punkts">лампадки та свічки</p>
                                <p onClick={this.selectPunktB} id = 'recent_punktb' className="recent_punkts">покривала</p>
                                <p onClick={this.selectPunktC} id = 'recent_punktc' className="recent_punkts">домовини</p>
                                <p onClick={this.selectPunktH} id = 'recent_punkth' className="recent_punkts">хрести</p>
                                <p onClick={this.selectPunktV} id = 'recent_punktv' className="recent_punkts">вінки</p>
                                <p onClick={this.selectPunktW} id = 'recent_punktw' className="recent_punkts">одяг та взуття</p>
                            
                        </div>
                    </div>
                    <div className="product__carousel_con">
                        <div className="product__carousel">
                            

                            {this.state.fproducts !== null? (
                            this.state.fproducts.map((fproduct,index) =>(
                                <CarouselCard addToCart={this.props.addToCart} stateType = {this.state.typeChanged} key={index} fproduct = {fproduct}/>
                            ))
                            ):'f'}
                            
                            
                        </div>
                    </div>
                    <div className="show_more">
                        <NavLink to={this.state.redirect}>
                            Показати більше...
                        </NavLink>
                    </div>
                </div>
                <div className="last_product">
                    <div className='recent_produts' id='recent_produts'>
                        <p className='recent_produts_text'>Останні товари у категорії </p>
                        <div onClick={this.selectSMenuOpener} name="" id="" className='select'>
                            {this.state.spunkt}
                            <img id="s_sarrow" src="/img/polygon.svg" alt="polygon 2" title='polygon 2' />
                        </div>
                        <div id='srecent_dropdown' className='recent_dropdown'>
                                <p onClick={this.selectsPunktL} id = 'srecent_punkt' className="recent_punkts">лампадки та свічки</p>
                                <p onClick={this.selectsPunktB} id = 'srecent_punktb' className="recent_punkts">покривала</p>
                                <p onClick={this.selectsPunktC} id = 'srecent_punktc' className="recent_punkts">домовини</p>
                                <p onClick={this.selectsPunktH} id = 'srecent_punkth' className="recent_punkts">хрести</p>
                                <p onClick={this.selectsPunktV} id = 'srecent_punktv' className="recent_punkts">вінки</p>
                                <p onClick={this.selectsPunktW} id = 'srecent_punktw' className="recent_punkts">одяг та взуття</p>
                            
                        </div>
                    </div>
                    <div className="product__carousel_con">
                        <div className="product__carousel">
                        {this.state.sproducts !== null? (
                            this.state.sproducts.map((sproduct,index) =>(
                               <SCarouselCard addToCart={this.props.addToCart} stateType = {this.state.stypeChanged} key={index} sproduct = {sproduct}/>
                            ))
                            ):'f'}
                            
                        </div>
                    </div>
                    <div className="show_more">
                        <NavLink to={this.state.sredirect}>
                            Показати більше...
                        </NavLink>
                    </div>
                </div>
                
                <div className="all_products">
                    <NavLink to='/select_category' onClick={this.scrollToTop} className="all_btn">Всі товари</NavLink>
                </div>


                <div onClick={this.scrollToTop} id="toTopArrow" className="toTopArrow">
                    <div id="arrow_part_to_top_left" className='arrow_part_to_top'></div>
                    <div id="arrow_part_to_top_right" className='arrow_part_to_top'></div>
                </div>
                <FooterUp />
                <FooterBot />
            </main>
        )
    }
}