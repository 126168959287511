import React from 'react';
import './dist/cartcardmenu.css'


export class CartCardMenu extends React.Component {
    constructor(props) {
        super(props)
    }

    removeFromCartLocal = (e) =>{
        e.preventDefault();
        this.props.removeFromCart(this.props.fullData.length-1-this.props.index)
    }
    

    render() {
        return (
            <div className='totalcartcardmenu'>
                <div className="cartmenucard">
                    <div className="menucardimgcon">
                        <img className="menucardimg" src={'https://requiem.com.ua' + (this.props.data?.img?this.props.data?.img:"")} alt="menu-card" title='menu-card' />
                    </div>
                    <div className="menucardtext">
                        <div className="menucardtitle">
                            {this.props.data.name?this.props.data.name:"..."}
                        </div>
                        <div className="aboutmenublock">
                            <div className="menucardinfoblock">
                                {Object.keys(this.props.data).length === 8 && this.props.data.flower_type && (
                                    <React.Fragment>
                                        <div className="menucardinfo">матеріал</div>
                                        <div className="menucardinfo">матеріал каркасу</div>
                                        <div className="menucardinfo">квіти</div>
                                    </React.Fragment>
                                )}
                                {Object.keys(this.props.data).length === 8  && (
                                    <React.Fragment>
                                        <div className="menucardinfo">матеріал</div>
                                        <div className="menucardinfo">покриття</div>
                                        <div className="menucardinfo">розмір</div>
                                    </React.Fragment>
                                )}
                                {Object.keys(this.props.data).length === 7  && this.props.data.blanket_type && (
                                    <React.Fragment>
                                        <div className="menucardinfo">тип</div>
                                        <div className="menucardinfo">декорації</div>
                                        <div className="menucardinfo">розмір</div>
                                    </React.Fragment>
                                )}
                                {Object.keys(this.props.data).length === 5 && (
                                    <React.Fragment>
                                        <div className="menucardinfo">матеріал</div>
                                    </React.Fragment>
                                )}
                                {Object.keys(this.props.data).length === 7  && this.props.data.description && (
                                    <React.Fragment>
                                        <div className="menucardinfo">опис</div>
                                        <div className="menucardinfo">розмір</div>
                                        <div className="menucardinfo">матеріал</div>
                                    </React.Fragment>
                                )}
                                {Object.keys(this.props.data).length === 6  && (
                                    <React.Fragment>
                                        <div className="menucardinfo">матеріал</div>
                                        <div className="menucardinfo">покриття</div>
  
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="menucardaboutblock">
                                {Object.keys(this.props.data).length === 8 && this.props.data.flower_type && (
                                        <React.Fragment>
                                            <div className="menucardinfo">{this.props.data.material?this.props.data.material:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.frame_material?this.props.data.frame_material:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.flower_type?this.props.data.flower_type:"..."}</div>
                                        </React.Fragment>
                                    )}
                                    {Object.keys(this.props.data).length === 8  && (
                                        <React.Fragment>
                                            <div className='menucardabout'>{this.props.data.material?this.props.data.material:"..."}</div>
                                            <div className='menucardabout'>{this.props.data.coating?this.props.data.coating:"..."}</div>
                                            <div className='menucardabout'>{this.props.data.size?this.props.data.size:"..."}</div>
                                        </React.Fragment>
                                    )}
                                    {Object.keys(this.props.data).length === 7  && this.props.data.blanket_type && (
                                        <React.Fragment>
                                            <div className="menucardinfo">{this.props.data.blanket_type?this.props.data.blanket_type:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.blanket_decoration?this.props.data.blanket_decoration:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.blanket_size?this.props.data.blanket_size:"..."}</div>
                                        </React.Fragment>
                                    )}
                                    {Object.keys(this.props.data).length === 5 && (
                                        <React.Fragment>
                                            <div className="menucardinfo">{this.props.data.candle_type?this.props.data.candle_type:"..."}</div>
                                        </React.Fragment>
                                    )}
                                    {Object.keys(this.props.data).length === 7  && this.props.data.description && (
                                        <React.Fragment>
                                            <div className="menucardinfo">{this.props.data.description?this.props.data.description:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.size?this.props.data.size:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.material?this.props.data.material:"..."}</div>
                                        </React.Fragment>
                                    )}
                                    {Object.keys(this.props.data).length === 6  && (
                                        <React.Fragment>
                                            <div className="menucardinfo">{this.props.data.material?this.props.data.material:"..."}</div>
                                            <div className="menucardinfo">{this.props.data.cross_coating?this.props.data.cross_coating:"..."}</div>
    
                                        </React.Fragment>
                                    )}
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={this.removeFromCartLocal} className='cancel_menu_btn'>
                    <img className='cancel_menu_btn_img' src="./img/cancel.svg" alt="Відмінити" title='Відмінити' />
                </div>
            </div>

        )
    }
}