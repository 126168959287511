import { React, Component } from 'react';
import { FooterBot } from '../../components/FooterBot/footerbot';
import { Helmet } from 'react-helmet';
import './dist/Contacts.css';


import getMethods from '../../services/getMethods';


export class Contacts extends Component {
  constructor(props){
    super(props)

    this.wait_send_or_call_local = this.wait_send_or_call_local.bind(this)

    this.state = {
      user_name:'',
      user_phone: '',
      user_message: ''
    }
  }

  nameKeeper = (e) => {
    let raw_value = e.target.value
    let default_value = raw_value.charAt(0).toUpperCase() + raw_value.slice(1)

    this.setState({user_name:default_value})
  }
  phoneKeeper = (e) => {
    const rawValue = e.target.value;
        const formattedValue = rawValue
            .replace(/[^0-9+\-\s]/g, '')
            .replace(/\s+/g, '-')
        if(this.state.user_phone.length < 17){
            this.setState({user_phone: formattedValue})
        }else {
            this.setState((prevState) => ({
                user_phone: prevState.user_phone.slice(0, -1) 
            }));
        }
  }
  messageKeeper = (e) => {
    this.setState({user_message: e.target.value})
  }
  

  async wait_send_or_call_local(){
      try {
        if(this.state.user_name !== '' && this.state.user_phone !== '' && this.state.user_message !== ''){
          const responce = await getMethods.formSender(
            this.state.user_name,
            this.state.user_phone,
            null,
            null,
            this.state.user_message
          )
  
          this.setState({user_name:'',user_phone:'',user_message:''})
          return responce
  
        }
        
      } catch (error) {
          this.setState({user_name:'',user_phone:'',user_message:''})
      }
      
        
    
  }



  render() {
    return (
      <div className='contacts_page'>

        <Helmet>
          <title>Контакти. Магазин ритуальних послуг Реквієм у Львові.</title>
          <meta name="description" content="Магазин ритуальних послуг Реквієм у Львові. Контакти магазину ритуальних послуг Реквієм. м.Львів,вулиця Костя Левицького,71,79000,Львівська область." />
          <meta property="og:title" content="Контакти. Магазин ритуальних послуг Реквієм у Львові."/>
          <meta property="og:description" content="Магазин ритуальних послуг Реквієм у Львові. Контакти магазину ритуальних послуг Реквієм. м.Львів,вулиця Костя Левицького,71,79000,Львівська область."/>

        
        </Helmet>

        <div className="contact_block">
          <div className='contact_info'>
            <h1 className='contact_headig'>Контакти</h1>
            <div className='con_adress'>
              <h2 className = 'con_adress_text'>м. Львів</h2>
              <h2 className = 'con_adress_text'>вулиця Костя Левицького , 71</h2>
              <h2 className = 'con_adress_text'>Львівська область, 79000</h2>
            </div>
            <div className='con_phones'>
              <h2 className='cons_title'>номери телефонів |</h2>
              <a className='flexer_con' href="tel:+380-67-673-03-64>">
                <div className='con_phone_numbers'>
                  <img className='con_icon_phone' src="./img/contact_phone.svg" alt="+380-67-673-03-64" title = '+380-67-673-03-64'/>
                  <h3 className='cons_info'>+380-67-673-03-64</h3>

                </div>
              </a>
              <a className='flexer_con' href="tel:+380-98-249-60-50">
                <div className='con_phone_numbers'>
                  <img className='con_icon_phone' src="./img/contact_phone.svg" alt="+380-98-249-60-50" title = '+380-98-249-60-50' />
                  <h3 className='cons_info'>+380-98-249-60-50</h3>

                </div>
              </a>
            </div>
            <div className='con_email'>
              <h2 className='cons_title_e'>пошта |</h2>
              <h3 className='cons_info_e'>@memory.ritual.lviv@gmail.com  </h3>
            </div>
            <div className="to_next_btn_con">
              <a href="#form_block"><button className='to_next_block_button_connect'>Зв'яжіться з нами</button></a>
            </div>
          </div>
          <iframe className='g_map' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2573.556189487583!2d24.042135976432807!3d49.83200607148065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add4359b63547%3A0xf7667c24b661a39a!2z0YPQuy4g0JvQtdCy0LjRhtC60L7Qs9C-LCA3MSwg0JvRjNCy0L7Qsiwg0JvRjNCy0L7QstGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCA3OTAwMA!5e0!3m2!1sru!2sua!4v1687179559669!5m2!1sru!2sua" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        </div>
        <div className='social_m_b'>
          <a href="mailto: memory.ritual.lviv@gmail.com"><img className='social_media_Link' src="./img/emailbox.svg" alt="Електрона пошта" title = 'Електрона пошта' /></a>
          <a href="https://t.me/+380982496050"><img className='social_media_Link' src="./img/telegram.svg" alt="Телеграм номер" title = 'Телеграм номер' /></a>
          <a href="https://tinyurl.com/4uzd6ed9"><img className='social_media_Link' src="./img/viber.svg" alt="Вайбер номер" title = 'Вайбер номер' /></a>
        </div>

        <div id='form_block'>
          <h2 className='form__title'>Зворотній зв’язок</h2>
          <div className='form' action="">
            <div className='input_block'>

              <div className='left_part_f'>
                <div className="input_con">
                  <input value={this.state.user_name}  onChange={this.nameKeeper} placeholder='gg' id="name_input" name='name_input' className="input"  type="text" required/>
                  <label className="input_label" htmlFor="name_input">Ім'я</label>
                </div>
                <div className="input_con">
                  <input value={this.state.user_phone} onChange={this.phoneKeeper} placeholder='gg' id='phone_num' name='phone_num' className='input' type="text" required/>
                  <label htmlFor='phone_num' className='input_label'>Номер телефону</label>
                </div>

              </div>
              <div className='right_part_f'>
                <div className='message_block'>
                  <textarea value={this.state.user_message} onChange={this.messageKeeper} type = "text" placeholder='gg' name='massage' id='massage' className='message_input' required></textarea>
                  <label htmlFor='massage' className='message_label'>Ваше повідомлення</label>

                </div>
              </div>

            </div>
            <button onClick={this.wait_send_or_call_local} className='send_button'>Надіслати</button>
          </div>
        </div>
        <FooterBot/>
      </div>
    )
  }
}
