import React from 'react';

import './dist/notfound.css';

export class NotFound extends React.Component {
    render() {
        return(
            <div className="NotFoundCont">
                <div className="NotFoundText">Сторінку не знайдено :C </div>
            </div>
        )
    }
}