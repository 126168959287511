import { React, Component } from 'react';
import './dist/footerUp.css'
import getMethods from '../../services/getMethods';



export class FooterUp extends Component {
    constructor(props){
        super(props)
        this.wait_call_me = this.wait_call_me.bind(this)
        this.state = {
            phoneNumber: "",
        }
    }
    

    
    async wait_call_me () {
        if(this.state.phoneNumber !== ""){
            const responce = await getMethods.formSender(
                null,
                this.state.phoneNumber,
                null,
                null
            )
            return responce
        }
     }

     callMe = async() =>{
        try {
            if(this.state.phoneNumber.length > 9){
                await this.wait_call_me()
                this.setState({ phoneNumber: ''})
            }
            
        } catch (error) {
            this.setState({ phoneNumber: ''})
        }
        
     }

    inputStabilizator = (e) =>{
        const rawValue = e.target.value;
        const formattedValue = rawValue
            .replace(/[^0-9+\-\s]/g, '')
            .replace(/\s+/g, '-')
        if(this.state.phoneNumber.length < 17){
            this.setState({phoneNumber: formattedValue})
        }else {
            this.setState((prevState) => ({
                phoneNumber: prevState.phoneNumber.slice(0, -1) 
            }));
        }
    }
    
    
    
    render() {
        return (
            <footer className="footer_up_container">
                    <div className="left_side">
                        <p className="consult">Потрібна консультація?</p>
                        <div className="num_con">
                        <input value={this.state.phoneNumber} onChange = {this.inputStabilizator} className="number_input" id="number_input" name="number_input" placeholder="+380-XX-XXX-XX-XX"/>
                        <label onClick={this.callMe} htmlFor="number_input" className="phone_me">Зателефонуйте мені!</label>
                     </div>
                    </div>
                    <div className="right_side">
                      <a className="number_foot" href="tel:+380676730364" title='Максим'>+380-67-676-03-64</a>
                      <a className="number_foot2" href="tel:+380982496050" title='Ірина'>+380-98-249-60-50</a>
                    </div>

                 
            </footer>
        )
    }
}