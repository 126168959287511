import axios from 'axios'
import Cookies from 'js-cookie';


let token = null;

const tokenGetter = (cookieString) => {
  if (window.location.pathname === "/admin") {

      const cookies = document.cookie;

      // Разбить строку кук на отдельные куки
      const cookieArray = cookies.split('; ');

      // Найти куку с токеном
      for (const cookie of cookieArray) {
        const [name, value] = cookie.split('=');
        if (name === 'token') {
          token = value;
          break;
        }
      }

      // Делайте что-то с полученным токеном
      if (token) {
        console.log('Токен:', token);
        // Дополнительные действия с токеном...
      } else {
        console.log('Токен не найден');
      }
   }
   

}


tokenGetter(document.cookie)



const host = 'https://requiem.com.ua'

const getProducts = () => {
    const headers = {
        'Content-Type': 'application/json',

    };


    return axios.get('https://requiem.com.ua/api/get_coffin', {headers})
      .then((response) => {
        return response.data;
      });
  };

  const login = (
      username,
      password
    ) =>{
      const headers = {
        'Content-Type': 'application/json',
      };

      const request = {
        username : username,
        password : password
      }

      return axios.post(host + '/api/login/', request, {headers})
        .then((response) => {
          return response.data;
        });
  }

  const coffinPost = (
    name,
    price,
    size,
    material,
    furnishing,
    internal_decoration,
    coating,
    images,
    uploaded_images
) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    };

    const request = new FormData();

    request.append('name', name);
    request.append('price', price);
    request.append('size', size);
    request.append('material', material);
    request.append('furnishing', furnishing);
    request.append('internal_decoration', internal_decoration);
    request.append('coating', coating);
    request.append('images', images);

    for (const uploadedImage of uploaded_images) {
        request.append('uploaded_images', uploadedImage);
    }

    return axios.post(host + '/api/add_coffin/', request, { headers })
        .then((response) => {
            return response.data;
        });
}




  const wreathsPost = (
    name,
    price,
    size,
    material,
    frame_material,
    flower_type,
    images,
    uploaded_images
) => {

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${token}`,
  };

  const request = new FormData();
    request.append("name", name)
    request.append("price", price)
    request.append("size", size)
    request.append("material", material,)
    request.append("frame_material", frame_material)
    request.append("flower_type", flower_type)
    request.append("images", images)
  
    for (const uploadedImage of uploaded_images) {
        request.append('uploaded_images', uploadedImage);
    }

  return axios.post(host + '/api/add_wreath/', request, {headers})
        .then((response) => {
          return response.data;
        });
}

const crossPost = (
  name,
  price,
  material,
  images,
  cross_coating,
  uploaded_images
) => {

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${token}`,
};

const request = new FormData();
  request.append("name", name)
  request.append("price", price)
  request.append("material", material)
  request.append("images", images)
  request.append("cross_coating", cross_coating)

  for (const uploadedImage of uploaded_images) {
    request.append('uploaded_images', uploadedImage);
}



  

return axios.post(host + '/api/add_cross/', request, {headers})
      .then((response) => {
        return response.data;
      });
}


const candlePost = (
  name,
  price,
  images,
  uploaded_images,
  candle_type
) => {

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${token}`,
};



const request = new FormData();

  request.append('name', name);
  request.append('price', price) 
  request.append('images',images) 

  request.append('candle_type', candle_type) 
  for (const uploadedImage of uploaded_images) {
    request.append('uploaded_images', uploadedImage);
  }

return axios.post(host + '/api/add_candles/', request, {headers})
      .then((response) => {
        return response.data;
      });
}


const blanketPost = (
  name,
  price,
  images,
  blanket_type,
  blanket_size,
  blanket_decoration,
  uploaded_images
) => {

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${token}`,
};

const request = new FormData();

  request.append('name', name);
  request.append('price', price);
  request.append('images', images);
  request.append('blanket_type', blanket_type);
  request.append('blanket_size', blanket_size);
  request.append('blanket_decoration', blanket_decoration);
  for (const uploadedImage of uploaded_images) {
    request.append('uploaded_images', uploadedImage);
  }

return axios.post(host + '/api/add_blanket/', request, {headers})
      .then((response) => {
        return response.data;
      });
}


const clothPost = (
  size,
  description,
  material,
  name,
  price,
  images,
  uploaded_images
) => {

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${token}`,
};

const request = new FormData();
  request.append("size", size)
  request.append("description", description)
  request.append("material", material)
  request.append("name", name)
  request.append("price", price)
  request.append("images", images)
  for (const uploadedImage of uploaded_images) {
    request.append('uploaded_images', uploadedImage);
  }



return axios.post(host + '/api/cloth_and_shoes/add/', request, {headers})
      .then((response) => {
        return response.data;
      });
}


const shoesPost = (
  name,
  price,
  images,
  description,
  size
) => {

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${token}`,
};

const request = {
  name: name,
  price: price,
  images: images,
  description: description,
  size: size
}

return axios.post(host + '/api/add_shoes/', request, {headers})
      .then((response) => {
        return response.data;
      });
}


  const getCrosses = () => {
    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host + '/api/get_crosses/', {headers})
      .then((response) => {
        return response.data;
      });
  };



  const getCandles = () => {
    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host + '/api/get_candles', {headers})
      .then((response) => {
        return response.data;
      });
  };


  const getCoffImg = (content_type,id) => {

    const data = {
      content_type: content_type,
      id: id,
  
    }


    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host + `/api/upload_images/${content_type}/${id}` ,data ,  {headers})
      .then((response) => {
        return response.data;
      });
  };


  const getWreaths = () => {
    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host + '/api/get_wreaths', {headers})
      .then((response) => {
        return response.data;
      });
  };


  const getClothes = () => {
    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host + '/api/get_cloth_and_shoes/', {headers})
      .then((response) => {
        return response.data;
      });
  };


  const getBlankets = () => {
    const headers = {
        'Content-Type': 'application/json',
    };


    return axios.get(host +'/api/get_blankets', {headers})
      .then((response) => {
        return response.data;
      });
  };


  const formSender = (
    name,
    phone_number,
    email,
    products,
    user_message
  ) => {
    const reqData = {
      name: name,
      phone_number: phone_number,
      email: email,
      products: products,
      user_message: user_message
    }


    return axios.post(host + '/api/contact/',reqData)
    .then((response) =>{
      return response.data
    })
  }


  const getMethods = {
    getProducts,
    login,
    getCrosses,
    getCandles,
    getCoffImg,
    getWreaths,
    getClothes,
    getBlankets,
    coffinPost,
    wreathsPost,
    candlePost,
    clothPost,
    crossPost,
    blanketPost,
    shoesPost,
    formSender,
    tokenGetter


  }


  export default getMethods




