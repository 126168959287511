import React ,{ useCallback } from 'react';
import './dist/thanksform.css'
import { useNavigate } from 'react-router-dom';

export default function ThanksForm() {
    const nav = useNavigate()

    const navToMain = useCallback(()=>{
        nav('/')
    },[nav])
    
    return(
        <div className='thanksForm_con'>
            <div className='thanksForm'>
                <h1 className='thanxTitle'>Дякуємо за замовлення</h1>
                <p className='thanxText'>Ваше замовлення було відправлене та буде опрацьоване нашими співробітниками в найближчий час</p>

                <div className='thanxText'>Земний шлях короткий, пам'ять вічна</div>
                <div id='requiem' className='thanxText'>Реквієм</div>

                <button className='BackButton' onClick={navToMain} >Повернутись на головну</button>
            </div>
        </div>

    )

}