import React from "react";

import './dist/pvp.css'




export class PageImageViewer extends React.Component {



    constructor(props){
        super(props)

        this.state = {
            vphotov: 0
        }

        this.closeViewer = this.closeViewer.bind(this)
        this.imgChanger = this.imgChanger.bind(this)

    }


    imgChanger(e, index){
        e.stopPropagation()
        this.setState({vphotov: index})
    }

    

    closeViewer(){
        document.getElementById('pvp_viewer').style.opacity = '0'
        document.body.style.overflowY = 'auto'
        setTimeout(() =>{
            document.getElementById('pvp_viewer').style.display = 'none'
        },200)

        
    }

    


    render(){
        return(
            <div onClick={this.closeViewer} id="pvp_viewer" className="pvp_viewer">
                <img className="img_view" src={'http://requiem.com.ua'+ (this.props.photos?this.props.photos[this.state.vphotov]?this.props.photos[this.state.vphotov].image:'':'')} alt="" />
                <div className="img_v_carousel_con">
                        {this.props.photos?this.props.photos.map((photo,index)=>(
                           <div onClick={(e)=> this.imgChanger(e,index)} className="carousel_card_con" key={index}>
                                <img className="carousel_card"  src={'http://requiem.com.ua'+photo.image}/>
                           </div>
                        )):''}
                </div>

            </div>
        )
    }
}