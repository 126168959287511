import React from 'react';
import './dist/pselection.css';
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';




export class PSelection extends React.Component {
    render(){
        return(
            <div className="prod_selection_page">
                <Helmet>
                    <title>Ритуальні товари. Магазин ритуальних послуг Реквієм у Львові</title>
                    <meta name="description" content="Магазин ритуальних послуг Реквієм у Львові. Ритуальні товари та ритуальні послуги за доступною ціною. Багатий асортимент ритуальних товарів для похорону. Ритуальні послуги та ритуальні товари у Львові та Львівській області."/>
                    <meta property="og:title" content="Ритуальні товари. Магазин ритуальних послуг Реквієм у Львові"/>
                    <meta property="og:description" content="Магазин ритуальних послуг Реквієм у Львові. Ритуальні товари та ритуальні послуги за доступною ціною. Багатий асортимент ритуальних товарів для похорону. Ритуальні послуги та ритуальні товари у Львові та Львівській області."/>
                </Helmet>
                
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = 'background' />
                </header>
                <main className="category_block">
                    <NavLink to='/coffin'>
                        <div className='category_element'>
                            <img className="img_cat_sizer" src="./img/Grob.svg" alt="Домовина" title = 'домовина'/>
                            <h2 className="category_text_sel">Домовини</h2>
                        </div>
                    </NavLink>
                    <NavLink to='/crosses'>
                    <div className='category_element'>
                    
                        <img className="img_cat_sizer" src="./img/hrest.svg" alt="Хрест" title = 'Хрест' />
                        <h2 className="category_text_sel">Хрести</h2>
                    </div>
                    </NavLink>
                    <NavLink to='/wreaths'>
                    <div className='category_element'>
                        <img className="img_cat_sizer" src="./img/zhiviVinki.svg" alt="Вінок" title = 'Вінок' />
                        <h2 className="category_text_sel">Живі вінки/Штучні вінки</h2>
                    </div>
                    </NavLink>
                    <NavLink to='/veils'>
                    <div className='category_element'>
                        <img className="img_cat_sizer" src="./img/pokrivala.svg" alt="Покривала" title = 'Покривала' />
                        <h2 className="category_text_sel">Покривала</h2>
                    </div>
                    </NavLink>
                    <NavLink to='/lampscandles'>
                    <div className='category_element'>
                        <img className="img_cat_sizer" src="./img/Rectangle(11).svg" alt="Лампадка" title = 'Лампадка' />
                        <h2 className="category_text_sel">Лампадки та свічки</h2>
                    </div>
                    </NavLink>
                    <NavLink to='/clothes'>
                    <div className='category_element'>
                        <img className="img_cat_sizer" src="./img/odyag.svg" alt="Одяг" title = 'Одяг' />
                        <h2 className="category_text_sel">Одяг та Взуття</h2>
                    </div>
                    </NavLink>
                </main>
                <FooterUp />
                <FooterBot />
            </div>
        )
    }
}