import React,{useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import getMethods from '../../services/getMethods';

import './dist/LoginPage.css'


export function LoginPage() {

    const nav = useNavigate()
    const [login,setLogin] = useState('')
    const [password,setPassword] = useState('')
    const [eye, setEye] = useState('./img/Frame.svg')
    const [eyeState, setEyeState] = useState(false)
    const [type, setType] = useState('password')

    const wait_admin = async (login, password) => {
      try {
        const response = await getMethods.login(login, password);
        document.cookie = `token = ${response.access}`   
        if(document.cookie){
          nav('/admin');
        }     
      } catch (error) {
        document.getElementById("name_input_login").style.border = '2px solid red'
        document.getElementById("password_input_login").style.border = '2px solid red'
      }
    };




    const passTypeChange = () => {
      if(eyeState === false){
        setEye('./img/eye_close.svg')
        setEyeState(true)
        setType('text')

      }
      else{
        setEye('./img/Frame.svg')
        setEyeState(false)
        setType('password')

        
      }
    }


    const Logined = () =>{
      let username = document.getElementById("name_input_login");
      let password = document.getElementById("password_input_login");
      
      if(username.value !== '' || password.value !== ''){
        setLogin(username.value)
        setPassword(password.value)
      }
      
    }

    useEffect(()=>{
      if(login !== '' || password !== ''){
        wait_admin(login,password)
      }
      
    },[login,password])

    const toMain = () => {
      nav('/')
    }


    //from submit
    useEffect(() => {
      window.addEventListener("keydown",(e)=>{
          if(e.key === 'Enter'){
              e.preventDefault();
              Logined()
          }
      },false)
    }, []);



    useEffect(()=>{
      function clearCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      clearCookie('token')
    },[])
    
    useEffect(() => {
      //вернул хедер
    
        if(window.location.pathname ===  "/login"){
          document.getElementById('navbar').style.display = 'none'
    
        }
        else{
          document.getElementById('navbar').style.display = 'flex'
        }
        let lb = document.getElementById("login_button");
        let login_form = document.getElementById("login_form");
        let username = document.getElementById("name_input_login");
        let password = document.getElementById("password_input_login");
        let plabel = document.getElementById("password_label_login")
        let eye = document.getElementById('eye')

        const transformer = () => {
          if (username.value !== '' || password.value !== '') {
            if (lb.style.display !== "block") {
              lb.style.display = "block";
              login_form.style.height = "42.59vh";
              password.style.marginTop = "3.42vh";
              plabel.style.marginTop = "3.42vh";  
              eye.style.top = '5vh'            
            }
          } else {
            if (lb.style.display !== "none") {
              lb.style.display = "none";
              login_form.style.height = "23.05vh";
              plabel.style.marginTop = "0";
              password.style.marginTop = "0";
              eye.style.top = '1.4vh'            


            }
          }
        };
      
        username.addEventListener("input", transformer); 
        password.addEventListener("input", transformer); 
    
        
      }, []);

      

    


    return (
        <div className='LoginPageContainer'>
            <div className='MainTextCon'>
                <div onClick={toMain} className='title'>Реквієм</div>
                <div className='login-admin-text'>Увійти,як адміністратор</div>
            </div>
            <div id='login_form' className='login_form' action="">
                    <div className='inputs_main_form'>
                        <input id='name_input_login' name='name_input_login' placeholder='memory'  className='login_inputs' type="text"  title="Тут ви можете ввести свій логін ,це ваша пошта"  required/>
                        <label id='name_label_login' htmlFor="name_main" className='login_label'>Логін</label>
                    </div>
                    <div className='inputs_main_form'>
                        <input id='password_input_login' name='password_input_login' placeholder='memory' className='login_inputs' type={type} title="Тут ви можете ввести свій пароль"   required />
                        <label id='password_label_login' htmlFor="password_input_login" className='login_label'>Пароль</label>
                        <img onClick={passTypeChange} id='eye' className='eye' src={eye} alt="" />
                    </div> 
                    <button onClick={Logined} id="login_button" className='login_button'>Увійти</button>

            </div>
            <div className='login_down_text'>Земний шлях короткий, пам'ять - вічнa</div>
        </div>
    )

}