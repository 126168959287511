import { React, Component } from 'react';
import './dist/footerbot.css'
export class FooterBot extends Component {
    render() {
        return (
                <footer className='footer_container'>
                    <div className='text_left'>
                        <p className='rights'>All rights reserved</p>
                        <p className='memory_adress'>requiem.com.ua</p>   
                   </div>
                  <div className='text_right'>
                        <p  className='poshta_label'>Пошта |</p>
                        <nobr href="mailto: memory.ritual.lviv@gmail.com" className="poshta_text">mytorak23@gmail.com </nobr> 
                    </div>    
                
                </footer>
        )
    }   
}