import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { WreathCard } from "../../components/WreathCard/wreathcard";
import "./dist/WreathsPage.css";

import { Helmet } from 'react-helmet';


import getMethods from "../../services/getMethods";

export class WreathsPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            wrearth: sessionStorage.getItem("wreaths")?JSON.parse(sessionStorage.getItem("wreaths")) : null,
            local_wrearth: sessionStorage.getItem("wreaths")?JSON.parse(sessionStorage.getItem("wreaths")) : null,

        }

        this.waitWreaths = this.waitWreaths.bind(this)
        this.wrearth_capture = this.wrearth_capture.bind(this)

    }




    async waitWreaths(){
        const response = await getMethods.getWreaths()

        if(response){
            this.setState({
                wrearth: response,
                local_wrearth:response

            },()=>{
                sessionStorage.setItem('wreaths',JSON.stringify(response))
            })
        }

        return response

    }
    wrearth_capture(local_wrearth){
        this.setState({local_wrearth : local_wrearth})
    }


    async componentDidMount(){
        try {
            if(!this.state.wrearth)
                await this.waitWreaths()
        }     
        catch (error) {
            return 0
        }
    }
        


    render(){
        return(
            <div className="products_page">
                <Helmet>
                    <title>Вінки. Магазин ритуальних послуг Реквієм у Львові.</title>
                    <meta name="description" content="Вінки та живі вінки для похорону. Ритуальні живі та штучні вінки. Широкий асортимент живих та штучних вінків для похорону у Львові в нашому магазині ритуальних послуг Реквієм. Штучні та живі вінки у Львові та Львівській області." />
                    <meta property="og:title" content="Вінки. Магазин ритуальних послуг Реквієм у Львові."/>
                    <meta property="og:description" content="Вінки та живі вінки для похорону. Ритуальні живі та штучні вінки. Широкий асортимент живих та штучних вінків для похорону у Львові в нашому магазині ритуальних послуг Реквієм. Штучні та живі вінки у Львові та Львівській області."/>
                    
                </Helmet>
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = "background"/>
                </header>
                <h1 className="product_view_page_title">Вінки</h1>
                {/* <p className="description_w_text">
                    <h2 className="inline-heading">Вінки</h2>
                    є символом вшанування і пам'яті про покійного. Вони прикрашають<h2 className="inline-heading">місце поховання</h2> і є важливою частиною 
                    ритуалу прощання. Вінки можуть бути виготовлені зі 
                    <h2 className="inline-heading">штучних або живих квітів.</h2><h2 className="inline-heading">Живі вінки</h2> додають природної краси, символізуючи зміну і переродження. 
                    <h2 className="inline-heading">Штучні вінки</h2> можуть бути більш 
                    <h2 className="inline-heading">тривалими та стійкими</h2> до погодних умов. Вінки вибирають з урахуванням смаку і вподобань покійного або його родичів.
                </p> */}

                <div className="product_view_page">
                    
                    <div className="products_view">
                    {this.state.wrearth !== null? (
                            this.state.local_wrearth.map((wrearth,index) =>(
                                <WreathCard setLink = {this.props.setLink}  addToCart = {this.props.addToCart} cart={this.props.cart}  wrearth = {wrearth} key={index}/>
                            ))
                    ):'f'}

                    </div>
                </div>
                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}