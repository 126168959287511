import React from 'react';
import "./dist/ad.css";

import { FooterUp } from '../../components/FooterUp/footerUp';
import { FooterBot } from '../../components/FooterBot/footerbot';
import { AdminCheker } from './adminCheker';
import { AdminImageViewer } from './adImageViewer';
import getMethods from '../../services/getMethods';


export class AdminPanel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            categoryopener: false,
            categoryopenertwo: false,
            categoryopenerthree: false,
            categoryopenerfour: false,
            categoryopenerfive: false,
            categoryopenersix: false,
            categoryopenerSeven: false,
            categoryopenerEight: false,
            currentCatText: "Домовини",
            treeCurrentText: "Дуб",
            lakCurrentText:"Лак",
            AtlasCurrentText: "Атлас",
            FurnitureCurrentText: "Фарбована",
            OzdoblenText: "Є",
            kvitkaText: "Атлас",
            clothType: "Одяг",
            colorText: "Чорний",
            photo: [],
            photogolab:'',
            photov: 0,
            nameInput: '',
            sizeInput: '',
            priceInput: ''



        }
        this.handleClickCategory = this.handleClickCategory.bind(this)
        this.closeCategory = this.closeCategory.bind(this)
        this.vinkiClick = this.vinkiClick.bind(this)
        this.hrestClick = this.hrestClick.bind(this)
        this.grobClick = this.grobClick.bind(this)
        this.wearClick = this.wearClick.bind(this)
        this.pokrivaloClick = this.pokrivaloClick.bind(this)
        this.candelClick = this.candelClick.bind(this)
        this.computerCategory = this.computerCategory.bind(this)
        this.computerCategoryPickSecond = this.computerCategoryPickSecond.bind(this)
        this.computerCategoryPickThird = this.computerCategoryPickThird.bind(this) 
        this.computerCategoryPickFourth = this.computerCategoryPickFourth.bind(this)
        this.computerCategoryPickFive = this.computerCategoryPickFive.bind(this)
        this.computerCategoryPickSix = this.computerCategoryPickSix.bind(this)
        this.computerCategoryPickSeven = this.computerCategoryPickSeven.bind(this)
        this.computerCategoryPickEight = this.computerCategoryPickEight.bind(this)
        this.viewerOpener = this.viewerOpener.bind(this)
        this.viewStabilizator = this.viewStabilizator.bind(this)
        



        this.photoUpload = this.photoUpload.bind(this)
    }

    NameHandleChange = (e)=>{
        let raw_value = e.target.value
        let default_value = raw_value.charAt(0).toUpperCase() + raw_value.slice(1)
        this.setState({nameInput: default_value})
    }

    SizeHandleChange =(e) => {
        let raw_value = e.target.value 
        let default_value = raw_value
            .replace(/[^0-9хx\s]/g, '')
        this.setState({sizeInput: default_value})
    }
    
    PriceHandleChange =(e) => {
        let raw_value = e.target.value 
        let default_value = raw_value
            .replace(/[^0-9$₴€\s]/g, '')
        this.setState({priceInput: default_value})
    }

    testPost = async() =>{
        try {
            if(this.state.currentCatText === 'Домовини'){
                const response = await getMethods.coffinPost(
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.sizeInput,
                    this.state.treeCurrentText,
                    this.state.FurnitureCurrentText,
                    this.state.AtlasCurrentText,
                    this.state.lakCurrentText,
                    this.state.photogolab[0],
                    this.state.photogolab
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            } else if(this.state.currentCatText === 'Лампадки та свічки'){
                const response = await getMethods.candlePost(
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.photogolab[0],
                    this.state.photogolab,
                    this.state.treeCurrentText,
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            }
            else if(this.state.currentCatText === 'Вінки'){
                const response = await getMethods.wreathsPost(
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.sizeInput,
                    this.state.treeCurrentText,
                    'Метал',
                    this.state.kvitkaText,
                    this.state.photogolab[0],
                    this.state.photogolab
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            }
            else if(this.state.currentCatText === 'Хрести'){
                const response = await getMethods.crossPost(
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.treeCurrentText,
                    this.state.photogolab[0],
                    this.state.lakCurrentText,
                    this.state.photogolab
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            }
            else if(this.state.currentCatText === 'Покривала'){
                const response = await getMethods.blanketPost(
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.photogolab[0],
                    this.state.treeCurrentText,
                    this.state.sizeInput,
                    this.state.OzdoblenText,
                    this.state.photogolab
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            }
            else if(this.state.currentCatText === 'Одяг та взуття'){
                const response = await getMethods.clothPost(
                    this.state.sizeInput,
                    this.state.clothType,
                    this.state.treeCurrentText,
                    this.state.nameInput,
                    this.state.priceInput,
                    this.state.photogolab[0],
                    this.state.photogolab
                )
    
                this.setState({nameInput:'',priceInput:'',sizeInput:'',photogolab:'',photo:[]})
            }
            
        } catch (error) {
            console.log(error)
        }
       
        
    }



    photoUpload(e){
        const selectedImages = URL.createObjectURL(e.target.files[0]);
        const Image = e.target.files[0]
        this.setState({ photo: [...this.state.photo,selectedImages] });
        this.setState({photogolab: [...this.state.photogolab,Image]})
    

    }


    componentDidUpdate(){        
        if(this.state.photo.length ===1 ){
            document.getElementById('image_add_2').style.display = 'flex'
        }
        else if(this.state.photo.length ===2 ){
            document.getElementById('image_add_3').style.display = 'flex'
        }
        else if(this.state.photo.length ===3 ){
            document.getElementById('image_add_4').style.display = 'flex'
        }

    }

    viewerOpener(){
        document.getElementById('ad_viewer').style.display = 'flex'
        document.body.style.overflow = 'hidden'
        setTimeout(()=> {
            document.getElementById('ad_viewer').style.opacity = '1'
        },1)
    }


    computerCategory(){

            if(this.state.categoryopener === false){
            
            document.getElementById("picker_cat_first").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika').style.height = '36vh'
            this.setState({categoryopener: true})
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})



            
            
            

        }
        else if(this.state.categoryopener === true){
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            this.setState({categoryopener: false})
        }
        
    }

    computerCategoryPickSecond(){
        if(this.state.categoryopenertwo === false){
            document.getElementById("picker_cat_second").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_s').style.height = '36vh'
            this.setState({categoryopenertwo: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})


        }
        else if(this.state.categoryopenertwo === true){
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            this.setState({categoryopenertwo: false})
        }

    }

    computerCategoryPickThird(){
        if(this.state.categoryopenerthree === false){
            document.getElementById("picker_cat_third").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_t').style.height = '35.5vh'
            this.setState({categoryopenerthree: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})



        }
        else if(this.state.categoryopenerthree === true){
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            this.setState({categoryopenerthree: false})
        }

    }
    computerCategoryPickFourth(){
        if(this.state.categoryopenerfour === false){
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_f').style.height = '18vh'
            this.setState({categoryopenerfour: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})



        }
        else if(this.state.categoryopenerfour === true){
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            this.setState({categoryopenerfour: false})
        }

    }

    computerCategoryPickFive(){
        if(this.state.categoryopenerfive === false){
            document.getElementById("picker_cat_five").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_five').style.height = '24vh'

            this.setState({categoryopenerfive: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})
        }
        else if(this.state.categoryopenerfive === true){
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            this.setState({categoryopenerfive: false})
        }

    }

    computerCategoryPickSix(){
        if(this.state.categoryopenersix === false){
            document.getElementById("picker_cat_six").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_six').style.height = '13vh'

            this.setState({categoryopenersix: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenerSeven: false})
            this.setState({categoryopenerEight: false})




        }
        else if(this.state.categoryopenersix === true){
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            this.setState({categoryopenersix: false})
        }

    }
    computerCategoryPickSeven(){
        if(this.state.categoryopenerSeven === false){
            document.getElementById("picker_cat_seven").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '13vh'

            this.setState({categoryopenerSeven: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerEight: false})



        }
        else if(this.state.categoryopenerSeven === true){
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            this.setState({categoryopenerSeven: false})
        }

    }

    computerCategoryPickEight(){
        if(this.state.categoryopenerEight === false){
            document.getElementById("picker_cat_ei").style.transform = 'rotate(-45deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '12vh'

            this.setState({categoryopenerEight: true})
            document.getElementById("picker_cat_first").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika').style.height = '0vh'
            document.getElementById("picker_cat_second").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_s').style.height = '0vh'
            document.getElementById("picker_cat_third").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_t').style.height = '0vh'
            document.getElementById("picker_cat_fourth").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_f').style.height = '0vh'
            document.getElementById("picker_cat_five").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_five').style.height = '0vh'
            document.getElementById("picker_cat_six").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_six').style.height = '0vh'
            document.getElementById("picker_cat_seven").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_seven').style.height = '0vh'
            

            this.setState({categoryopener: false})
            this.setState({categoryopenertwo: false})
            this.setState({categoryopenerthree: false})
            this.setState({categoryopenerfour: false})
            this.setState({categoryopenerfive: false})
            this.setState({categoryopenersix: false})
            this.setState({categoryopenerSeven: false})
        }
        else if(this.state.categoryopenerEight === true){
            document.getElementById("picker_cat_ei").style.transform = 'rotate(0deg)'
            document.getElementById('picker_vypadaika_ei').style.height = '0vh'
            this.setState({categoryopenerEight: false})
        }
    }

    handleClickCategory(){
        document.getElementById("category_menu_a").style.height = "100vh"
    }   
    closeCategory(){
        document.getElementById("category_menu_a").style.height = "0vh"
    }
    vinkiClick(){
        const vinkiText = document.getElementById('vinki').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: vinkiText})
    }
    hrestClick(){
        const hrestText = document.getElementById('hrest').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: hrestText})
    }

    grobClick(){
        const grobText = document.getElementById('grob').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: grobText})
    }
    wearClick(){
        const wearText = document.getElementById('wear').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: wearText})
    }
    pokrivaloClick(){
        const pocrivaloText = document.getElementById('pokrivalo').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: pocrivaloText})
    }
    candelClick(){
        const candelText = document.getElementById('candel').textContent
        document.getElementById("category_btn").style.color = "#8D6321"
        document.getElementById("category_btn").style.borderColor = "#8D6321"
        document.getElementById("category_menu_a").style.height = "0vh"
        this.setState({category: candelText})
    }


    componentDidMount(){
        if(document.cookie){
            getMethods.tokenGetter(document.cookie)
        }else{
            console.log("we haven't cookie's((")
        }
           
        
        if(window.location.pathname ===  "/login"){
            document.getElementById('navbar').style.display = 'none'
          }
          else{
            document.getElementById('navbar').style.display = 'flex'
          }
    }

    
    viewStabilizator() {
        if(this.state.currentCatText === "Покривала"){
            document.getElementById('leftText').style.marginRight = '4.72vw'
        }
        else if(this.state.currentCatText === "Домовини"){
            document.getElementById('leftText').style.marginRight = '0vw'

        }
        else if(this.state.currentCatText === "Лампадки та свічки"){
            document.getElementById('leftText').style.marginRight = '6.72vw'
        }
    }
    
    

    render() {
        return (
            <div className="admin_panel_con">
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="bruh" />
                </header>
                <div className='product_settings_con'>
                    <div className='left_side_add_con'>
                        <div className='image_big_con'>
                            {this.state.photo.length === 0 ? (<img className='add_img' src='./img/Add_image.png'/>):(<img onClick={this.viewerOpener} className='added_img' src={this.state.photo[this.state.photov]}/>) }
                        </div>
                        <div id = 'small_img_add_con' className='small_img_add_con'>
                            <div id='image_add_1' className='image_add'>
                            {this.state.photo[0]? this.state.photo.length >0  ?(<img onClick={()=> this.setState({photov: 0})} className='added_img' src={this.state.photo[0]}/>):'': (<img src="./img/small_add_image.png"/>) }
                            </div>
                            <div id='image_add_2' className='image_add'>
                            {this.state.photo[1]? this.state.photo.length >0  ?(<img onClick={()=> this.setState({photov: 1})} className='added_img' src={this.state.photo[1]}/>):'': (<img src="./img/small_add_image.png"/>) }
                            </div>
                            <div id='image_add_3' className='image_add'>
                            {this.state.photo[2]? this.state.photo.length >0  ?(<img onClick={()=> this.setState({photov: 2})} className='added_img' src={this.state.photo[2]}/>):'': (<img src="./img/small_add_image.png"/>) }
                            </div>
                            <div id='image_add_4' className='image_add'>
                            {this.state.photo[3]? this.state.photo.length >0  ?(<img onClick={()=> this.setState({photov: 3})} className='added_img' src={this.state.photo[3]}/>):'': (<img src="./img/small_add_image.png"/>) }
                            </div>
                        </div>
                        <div className='add_photo_btn'>
                            {/* <button className='butt_ph'>Додати фото</button> */}
                            <label htmlFor="photoUpload" className="butt_ph">
                                Додати фото
                                <input multiple  onChange={this.photoUpload} type="file" id="photoUpload" className='rr'/>
                            </label>
                        </div>
                    </div>
                    <div className='right_side_add_con'>
                        <div id='leftText' className='left_text_side'>
                            <p id='name_text' className='name_text'>Назва</p>
                            <p id='categoryTitle' className='name_text'>Категорія</p>
                            <p id='materialTitle' className='name_text'>Матеріал</p>
                            <p id='pokrytTitle' className='name_text'>Покриття</p>
                            <p id='insideTitle' className='name_text'>Внутрішня тканина</p>
                            <p id='furnitureTitle' className='name_text'>Фурнітура</p>
                            <p id='ozdoblenniaTitle' className='name_text'>Оздоблення</p>
                            <p id='kvitkaTitle' className='name_text'>Квітка</p>
                            <p id='clothTypeTitle' className='name_text'>Тип Одягу</p>

                            <p className='name_text'>Розмір</p>
                            <p className='name_text'>Ціна від</p>

                        </div>

                        <div className='inputs_con'>
                            <input value={this.state.nameInput} onChange={this.NameHandleChange} className='name_input' type="text" />
                            <div className='pickers'>
                                <div id='mainPick' onClick={this.computerCategory} className='picker_con'>
                                    <div  className='picker_text'>{this.state.currentCatText}</div>
                                    <div id='picker_cat_first' className='picker'></div>
                                    <div id ='picker_vypadaika' className='picker_vypadaika'>

                                        <div onClick={()=>{
                                            this.setState({currentCatText:"Лампадки та свічки"},()=>{
                                                document.getElementById('out_pokr').style.display = 'none'
                                                document.getElementById('pokrytTitle').style.display = 'none'
                                                document.getElementById('insideTitle').style.display = 'none'
                                                document.getElementById('inside_tkan').style.display = 'none'
                                                document.getElementById('furniture').style.display = 'none'
                                                document.getElementById('furnitureTitle').style.display = 'none'
                                                document.getElementById('leftText').style.marginRight = '6.75vw'
                                                document.getElementById('ozdoblenniaTitle').style.display = 'none'
                                                document.getElementById('ozdoblennia').style.display = 'none'
                                                document.getElementById('clothTypeTitle').style.display = 'none'
                                                document.getElementById('cloth_type').style.display = 'none'

                                                

                                            })
                                         
                                        }} className='category_first'>лампадки та свічки</div>

                                        <div onClick={()=>{
                                            this.setState({currentCatText:"Покривала"},() => {
                                                document.getElementById('ozdoblenniaTitle').style.display = 'flex'
                                                document.getElementById('ozdoblennia').style.display = 'flex'
                                                document.getElementById('out_pokr').style.display = 'none'
                                                document.getElementById('pokrytTitle').style.display = 'none'
                                                document.getElementById('out_pokr').style.display = 'none'
                                                document.getElementById('inside_tkan').style.display = 'none'
                                                document.getElementById('insideTitle').style.display = 'none'
                                                document.getElementById('furniture').style.display = 'none'
                                                document.getElementById('furnitureTitle').style.display = 'none'
                                                document.getElementById('leftText').style.marginRight = '6.75vw'

                                            })
                                        }} className='category_first'>покривала</div>

                                        <div onClick={()=> {
                                            this.setState({currentCatText:"Домовини"},() => {
                                                document.getElementById('out_pokr').style.display = 'flex'
                                                document.getElementById('pokrytTitle').style.display = 'flex'
                                                document.getElementById('out_pokr').style.display = 'flex'
                                                document.getElementById('inside_tkan').style.display = 'flex'
                                                document.getElementById('insideTitle').style.display = 'flex'
                                                document.getElementById('furniture').style.display = 'flex'
                                                document.getElementById('furnitureTitle').style.display = 'flex'
                                                document.getElementById('ozdoblenniaTitle').style.display = 'none'
                                                document.getElementById('ozdoblennia').style.display = 'none'
                                                document.getElementById('leftText').style.marginRight = '0vw'


                                            })
                                        }} className='category_first'>домовини</div>

                                        <div onClick={()=> {
                                            this.setState({currentCatText:"Хрести"},()=>{
                                                document.getElementById('furniture').style.display = 'none'
                                                document.getElementById('furnitureTitle').style.display = 'none'
                                                document.getElementById('insideTitle').style.display = 'none'
                                                document.getElementById('inside_tkan').style.display = 'none'
                                                document.getElementById('leftText').style.marginRight = '6.75vw'


                                            })
                                        }} className='category_first'>хрести</div>

                                        <div onClick={()=> {
                                            this.setState({currentCatText:"Вінки"},() =>{
                                                document.getElementById('furniture').style.display = 'none'
                                                document.getElementById('furnitureTitle').style.display = 'none'
                                                document.getElementById('insideTitle').style.display = 'none'
                                                document.getElementById('inside_tkan').style.display = 'none'
                                                document.getElementById('ozdoblenniaTitle').style.display = 'none'
                                                document.getElementById('ozdoblennia').style.display = 'none'
                                                document.getElementById('out_pokr').style.display = 'none'
                                                document.getElementById('pokrytTitle').style.display = 'none'
                                                document.getElementById('kvitka').style.display = 'flex'
                                                document.getElementById('kvitkaTitle').style.display = 'flex'
                                                document.getElementById('leftText').style.marginRight = '6.75vw'



                                            })
                                        }} className='category_first'>вінки</div>

                                        <div onClick={()=> {
                                            this.setState({currentCatText:"Одяг та взуття"},() => {
                                                document.getElementById('furniture').style.display = 'none'
                                                document.getElementById('furnitureTitle').style.display = 'none'
                                                document.getElementById('insideTitle').style.display = 'none'
                                                document.getElementById('inside_tkan').style.display = 'none'
                                                document.getElementById('ozdoblenniaTitle').style.display = 'none'
                                                document.getElementById('ozdoblennia').style.display = 'none'
                                                document.getElementById('out_pokr').style.display = 'none'
                                                document.getElementById('pokrytTitle').style.display = 'none'
                                                document.getElementById('kvitka').style.display = 'none'
                                                document.getElementById('kvitkaTitle').style.display = 'none'
                                                document.getElementById('cloth_type').style.display = 'flex'
                                                document.getElementById('clothTypeTitle').style.display = 'flex'
                                                document.getElementById('leftText').style.marginRight = '6.75vw'

                                            })
                                        }} className='category_first'>одяг та взуття</div>
                                    </div>
                                </div>
                                
                                {/* Матеріал */}
                                <div id='material' onClick={this.computerCategoryPickSecond} className='picker_con'>
                                    <div className='picker_text'>{this.state.treeCurrentText}</div>
                                    <div id='picker_cat_second' className='picker'></div>
                                    <div id ='picker_vypadaika_s' className='picker_vypadaika'>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Дуб"})
                                        }} className='category_first'>дуб</div>
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Бук"})
                                        }} className='category_first'>бук</div>
 
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Смерека"})
                                        }} className='category_first'>смерека</div>
 
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Вільха"})
                                        }} className='category_first'>вільха</div>
 
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Сосна"})
                                        }} className='category_first'>сосна</div>
 
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Кедр"})
                                        }} className='category_first'>кедр</div>
 
                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Комбінований"})
                                        }} className='category_first'>комбінований</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Атлас"})
                                        }} className='category_first'>атлас</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Віск"})
                                        }} className='category_first'>віск</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Парафін"})
                                        }} className='category_first'>парафін</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Трикотаж"})
                                        }} className='category_first'>трикотаж</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Віскоза"})
                                        }} className='category_first'>віскоза</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Льон"})
                                        }} className='category_first'>льон</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Бавовна"})
                                        }} className='category_first'>бавовна</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Шкіра"})
                                        }} className='category_first'>шкіра</div>

                                         <div onClick={()=>{
                                            this.setState({treeCurrentText:"Замш"})
                                        }} className='category_first'>замш</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Метал"})
                                        }} className='category_first'>метал</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Штучна ялина"})
                                        }} className='category_first'>штучна ялина</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Скло"})
                                        }} className='category_first'>скло</div>

                                        <div onClick={()=>{
                                            this.setState({treeCurrentText:"Живі квіти"})
                                        }} className='category_first'>живі квіти</div>

                                    </div>
                                </div>

                                {/* Покриття */}
                                <div  id='out_pokr' onClick={this.computerCategoryPickThird} className='picker_con'>
                                    <div className='picker_text'>{this.state.lakCurrentText}</div>
                                    <div id='picker_cat_third' className='picker'></div>
                                    <div id ='picker_vypadaika_t' className='picker_vypadaika'>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Лак"})
                                        }} className='category_first'>лак</div>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Матовий лак"})
                                        }} className='category_first'>матовий лак</div>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Акрилова емаль"})
                                        }} className='category_first'>акрилова емаль</div>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Лак глянцевий"})
                                        }} className='category_first'>лак глянцевий</div>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Напівматовий"})
                                        }} className='category_first'>напівматовий</div>
                                       
                                        <div onClick={() => {
                                            this.setState({lakCurrentText:"Комбінований"})
                                        }} className='category_first'>комбінований</div>
                                        
                                    </div>
                                    
                                </div>
                                
                                {/* Внутріня тканина */}
                                <div id='inside_tkan' onClick={this.computerCategoryPickFourth} className='picker_con'>
                                    <div className='picker_text'>{this.state.AtlasCurrentText}</div>
                                    <div id='picker_cat_fourth' className='picker'></div>
                                    <div id ='picker_vypadaika_f' className='picker_vypadaika'>
                                        
                                        <div onClick={() => {
                                            this.setState({AtlasCurrentText:"Атлас"})
                                        }} className='category_first'>атлас</div>
                                       
                                        <div onClick={() => {
                                            this.setState({AtlasCurrentText:"Поліестер"})
                                        }}  className='category_first'>поліестер</div>
                                        
                                        <div onClick={() => {
                                            this.setState({AtlasCurrentText:"Оксамит"})
                                        }} className='category_first'>оксамит</div>
                                    </div>
                                </div>

                                {/* Фурнітура */}
                                <div id='furniture' onClick={this.computerCategoryPickFive} className='picker_con'>
                                    <div className='picker_text'>{this.state.FurnitureCurrentText}</div>
                                    <div id='picker_cat_five' className='picker'></div>
                                    <div id ='picker_vypadaika_five' className='picker_vypadaika'>
                                       
                                        <div onClick={() => {
                                            this.setState({FurnitureCurrentText:"Фарбована"})
                                        }} className='category_first'>фарбована</div>
                                        
                                        <div onClick={() => {
                                            this.setState({FurnitureCurrentText:"Лакована"})
                                        }} className='category_first'>лакована</div>
                                       
                                        <div onClick={() => {
                                            this.setState({FurnitureCurrentText:"Матова"})
                                        }} className='category_first'>матова</div>
                                       
                                        <div onClick={() => {
                                            this.setState({FurnitureCurrentText:"Пофарбована"})
                                        }} className='category_first'>пофарбована</div>
                                       
                                        <div onClick={() => {
                                            this.setState({FurnitureCurrentText:"Комбінована"})
                                        }} className='category_first'>комбінована</div>
                                    </div>
                                </div>
                                {/* Оздоблення */}
                                <div id='ozdoblennia' onClick={this.computerCategoryPickSix} className='picker_con'>
                                    <div className='picker_text'>{this.state.OzdoblenText}</div>
                                    <div id='picker_cat_six' className='picker'></div>
                                    <div id ='picker_vypadaika_six' className='picker_vypadaika'>
                                       
                                        <div onClick={() => {
                                            this.setState({OzdoblenText:"Є"})
                                        }} className='category_first'>є</div>
                                        
                                        <div onClick={() => {
                                            this.setState({OzdoblenText:"Немає"})
                                        }} className='category_first'>немає</div>
                                        
                                    </div>
                                </div>

                                {/* Квітка */}
                                <div id='kvitka' onClick={this.computerCategoryPickSeven} className='picker_con'>
                                    <div className='picker_text'>{this.state.kvitkaText}</div>
                                    <div id='picker_cat_seven' className='picker'></div>
                                    <div id ='picker_vypadaika_seven' className='picker_vypadaika'>
                                       
                                        <div onClick={() => {
                                            this.setState({kvitkaText:"Атлас"})
                                        }} className='category_first'>Атлас</div>
                                        
                                        <div onClick={() => {
                                            this.setState({kvitkaText:"Поліетилен"})
                                        }} className='category_first'>Поліетилен</div>

                                        <div onClick={() => {
                                            this.setState({kvitkaText:"Полістирол"})
                                        }} className='category_first'>Полістирол</div>
                                        
                                        <div onClick={() => {
                                            this.setState({kvitkaText:"Жива"})
                                        }} className='category_first'>Жива</div>
                                    
                                    </div>
                                        
                                </div>

                                {/* Тип одягу */}
                                <div id='cloth_type' onClick={this.computerCategoryPickEight} className='picker_con'>
                                    <div className='picker_text'>{this.state.clothType}</div>
                                    <div id='picker_cat_ei' className='picker'></div>
                                    <div id ='picker_vypadaika_ei' className='picker_vypadaika'>
                                       
                                        <div onClick={() => {
                                            this.setState({clothType:"Одяг"})
                                        }} className='category_first'>Одяг</div>
                                        
                                        <div onClick={() => {
                                            this.setState({clothType:"Взуття"})
                                        }} className='category_first'>Взуття</div>
                        
                                </div>
                                

                            </div>
                                



                            <div className='size_input_con'>
                                <input value={this.state.sizeInput} onChange={this.SizeHandleChange} className='size_input' type="text" /> 
                            </div>
                            <div className='price_input_con'>
                                <input value={this.state.priceInput} onChange={this.PriceHandleChange} className='price_input' type="text" />
                            </div>
                        </div>

                    </div>
                </div>


                <div className = "product_settings_mobile">
                    <div className="input_name_add">
                        <input id = "name_input_add" name = "name_input_add" className = "product_name_input" placeholder = "gg" type="text"/>
                        <label className = "name_label_add" htmlFor="name_input_add">Назва...</label>
                       
                    </div>
                    <div className="photo_add_block">
                        <img className = "big_add_img" src="./img/grob_big_pvp.png" alt="" />
                        <div className="s_img_block_m">
                            <div className="small_img_conm"></div>
                            <div className="small_img_conm"></div>
                            <div className="small_img_conm"></div>
                            <div className="small_img_conm"></div>
                        </div>
                    </div>
                    <div className="photo_add_btn_con">
                        <div className="photo_add_btn">Додати фото</div>
                    </div>

                    <div className="btn_add_info_con">
                        <div onClick={this.handleClickCategory} id='category_btn' className = "btn_add_info">{this.state.category}</div>
                        <div className = "btn_add_info">Матеріал</div>
                        <div className = "btn_add_info">Покриття</div>
                        <div className = "btn_add_info">Фурнітура</div>
                    </div>
                    <div className = "last_add_btn_con">
                        <div className = "last_add_btn">Внутрішня тканина</div>
                    </div>
                    <div className="input_size_add">
                        <input id = "size_input_add" name = "size_input_add" className = "product_size_input" type="text" placeholder = "gg"/>
                        <label className = "size_label_add" htmlFor="size_input_add">Розмір...</label>
                    </div>
                    
                        
                    
                 </div>
                 
                </div>
                <div className='add_product_butt'>
                            <button onClick={this.testPost} className='butt_prd'>Додати товар</button>
                         </div>
                <div id='category_menu_a' className="category_menu_a">
                    <img onClick={this.closeCategory} id='closebtn' className='c_b' src="./img/category_back.svg" alt="" />
                    <span id='candel' onClick={this.candelClick} className="category_menu_punkts">лампадки та свічки</span>
                    <span id='pokrivalo' onClick={this.pokrivaloClick} className="category_menu_punkts">покривала</span>
                    <span id='grob' onClick={this.grobClick} className="category_menu_punkts">домовини</span>
                    <span id='hrest' onClick={this.hrestClick} className="category_menu_punkts">хрести</span>
                    <span id='vinki' onClick={this.vinkiClick} className="category_menu_punkts">вінки</span>
                    <span id='wear' onClick={this.wearClick} className="category_menu_punkts">одяг та взуття</span>
                </div>  
                

                <AdminImageViewer photos = {this.state.photo}/>

                <AdminCheker/>
                <FooterUp />
                <FooterBot />
            </div>
        )
    }
}