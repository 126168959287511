import React from 'react';
import "./dist/pvp.css";
import {FooterUp} from '../../components/FooterUp/footerUp';
import { FooterBot } from '../../components/FooterBot/footerbot';

import { PageImageViewer } from './pvpViewer';




import getMethods from '../../services/getMethods';


export class ProductViewPage extends React.Component {

    constructor(props) {
        super(props);
        this.fImgBorderChanger = this.fImgBorderChanger.bind(this);
        this.sImagBorderChanger = this.sImagBorderChanger.bind(this);
        this.tImagBorderChanger = this.tImagBorderChanger.bind(this);

        this.waitCoffin = this.waitCoffin.bind(this);
        this.waitCrosses = this.waitCrosses.bind(this)
        this.foundProduct = this.foundProduct.bind(this)
        this.waitCoffImg = this.waitCoffImg.bind(this)
        this.waitCandles = this.waitCandles.bind(this)
        this.waitBlankets = this.waitBlankets.bind(this)
        this.waitWreaths= this.waitWreaths.bind(this)

        this.createCoffin = this.createCoffin.bind(this)
        this.createLambs = this.createLambs.bind(this)
        this.createBlanket = this.createBlanket.bind(this)
        this.createCrosses = this.createCrosses.bind(this)
        this.createWreathes = this.createWreathes.bind(this)
        this.createClothes = this.createClothes.bind(this)


        this.addToCartLocalView = this.addToCartLocalView.bind(this)
        this.viewerOpener = this.viewerOpener.bind(this)
        this.imgShower = this.imgShower.bind(this)

        this.state = {
            coffinObject:{
                id:'',
                name : '',
                material:'',
                coating:'',
                internal_decoration:'',
                size:'',
                price:'',
                img: null,               
            },
            lambObject:{
                id:'',
                name : '',
                candle_type:'',
                price:'',
                img: null,             
            },
            blankedObject:{
                id:'',
                name : '',
                blanket_type:'',
                blanket_size:'',
                blanket_decoration:'',
                price:'',
                img: null,
            },
            crossObject:{
                id: '',
                name : '',
                material:'',
                cross_coating:'',
                price:'',
                img: null,
                id: ''
            },
            wreathsObject:{
                id:'',
                name : '',
                material:'',
                frame_material:'',
                size:'',
                flower_type:"",
                price:'',
                img: null,
                
            },
            clothesObject:{
                id:'',
                name : '',
                material:'',
                size:'',
                price:'',
                img: null,
                
            },
            product: null,
            p: [],
            img: null,
            l_img: ''
        }
    }




    viewerOpener(){
        document.getElementById('pvp_viewer').style.display = 'flex'
        document.body.style.overflow = 'hidden'
        setTimeout(()=> {
            document.getElementById('pvp_viewer').style.opacity = '1'
        },1)
    }





    

    updateCoffin = (key, value) => {
        this.setState(prevState => ({
            coffinObject: {
            ...prevState.coffinObject,
            [key]: value
            }
        }));
        }
    updateLambs = (key, value) => {
        this.setState(prevState => ({
            lambObject: {
            ...prevState.lambObject,
            [key]: value
            }
        }));
        }
    updateBlankets = (key, value) => {
        this.setState(prevState => ({
            blankedObject: {
            ...prevState.blankedObject,
            [key]: value
            }
        }));
        }
    updateCrosses = (key, value) => {
        this.setState(prevState => ({
            crossObject: {
            ...prevState.crossObject,
            [key]: value
            }
        }));
        }
    updateWreaths = (key, value) => {
            this.setState(prevState => ({
                wreathsObject: {
                ...prevState.wreathsObject,
                [key]: value
                }
        }));
        }
    updateClothes = (key, value) => {
        this.setState(prevState => ({
            clothesObject: {
            ...prevState.clothesObject,
            [key]: value
            }
    }));
    }
    createCoffin(id,name,material,coating,internal_decoration,img,size,price){
        this.updateCoffin('id', id)
        this.updateCoffin('name', name)
        this.updateCoffin('material', material)
        this.updateCoffin('coating', coating)
        this.updateCoffin('internal_decoration', internal_decoration)
        this.updateCoffin('img', img)
        this.updateCoffin('size', size)
        this.updateCoffin('price', price)
    }
    createLambs(id,name,candle_type,img,price){
        this.updateLambs('id', id)
        this.updateLambs('name', name)
        this.updateLambs('candle_type', candle_type)
        this.updateLambs('img', img)
        this.updateLambs('price', price)

    }
    createBlanket( id,name,blanket_type,blanket_size,blanket_decoration,price,img){
        this.updateBlankets('id', id)
        this.updateBlankets('name', name)
        this.updateBlankets('blanket_type', blanket_type)
        this.updateBlankets('blanket_size', blanket_size)
        this.updateBlankets('blanket_decoration', blanket_decoration)
        this.updateBlankets('img', img)
        this.updateBlankets('price', price)

    }
    createCrosses(id,name ,material,cross_coating,price,img){
        this.updateCrosses('id', id)
        this.updateCrosses('name', name)
        this.updateCrosses('material', material)
        this.updateCrosses('cross_coating', cross_coating)
        this.updateCrosses('price', price)
        this.updateCrosses('img', img)
    }
    createWreathes(id,name ,material,frame_material,size,flower_type,price,img){
        this.updateWreaths('id', id)
        this.updateWreaths('name', name)
        this.updateWreaths('material', material)
        this.updateWreaths('frame_material', frame_material)
        this.updateWreaths('flower_type', flower_type)
        this.updateWreaths('size', size)
        this.updateWreaths('price', price)
        this.updateWreaths('img', img)
    }
    createClothes(id,name,description,img,size,price){
        this.updateClothes('id', id)
        this.updateClothes('name', name)
        this.updateClothes('description', description)
        this.updateClothes('img', img)
        this.updateClothes('size', size)
        this.updateClothes('price', price)

    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }

    async addToCartLocalView(e){
        e.preventDefault();
        if(this.state.p.description){
            await this.createClothes(
                this.state.p.id ? this.state.p.id:'',
                this.state.p.name ? this.state.p.name:'',
                this.state.p.description? this.state.p.description:'',
                this.state.img[0].image?this.state.img[0].image:'',
                this.state.p.size?this.state.p.size:'',
                this.state.p.price?this.state.p.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.clothesObject)
        }else if(this.state.p.flower_type){
            await this.createWreathes(
                this.state.p.id ? this.state.p.id:'',
                this.state.p.name ? this.state.p.name:'',
                this.state.p.material? this.state.p.material:'',
                this.state.p.frame_material? this.state.p.frame_material:'',
                this.state.p.size? this.state.p.size:'',
                this.state.p.flower_type? this.state.p.flower_type:'',
                this.state.p.price?this.state.p.price:'',
                this.state.img[0].image?this.state.img[0].image:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.wreathsObject)
        }else if(this.state.p.cross_coating){
            await this.createCrosses(
                    this.state.p.id ? this.state.p.id:'',
                    this.state.p.name ? this.state.p.name:'',
                    this.state.p.material? this.state.p.material:'',
                    this.state.p.cross_coating?this.state.p.cross_coating:'',
                    this.state.p.price?this.state.p.price:'',
                    this.state.img[0].image?this.state.img[0].image:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.crossObject)
        }else if(this.state.p.blanket_type){
            await this.createBlanket(
                this.state.p.id ? this.state.p.id:'',
                this.state.p.name ? this.state.p.name:'',
                this.state.p.blanket_type? this.state.p.blanket_type:'',
                this.state.p.blanket_size? this.state.p.blanket_size:'',
                this.state.p.blanket_decoration? this.state.p.blanket_decoration:'',
                this.state.p.price?this.state.p.price:'',
                this.state.img[0].image?this.state.img[0].image:'',
                )
                this.soundBoard()
                this.props.addToCart(this.state.blankedObject)
        }
        else if(this.state.p.candle_type){
            await this.createLambs(
                this.state.p.id ? this.state.p.id:'',
                this.state.p.name ? this.state.p.name:'',
                this.state.p.candle_type? this.state.p.candle_type:'',
                this.state.img[0].image?this.state.img[0].image:'',
                this.state.p.price?this.state.p.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.lambObject)
        }
        else if(this.state.p.internal_decoration){
            await this.createCoffin(
                this.state.p.id ? this.state.p.id:'',
                this.state.p.name ? this.state.p.name:'',
                this.state.p.material? this.state.p.material:'',
                this.state.p.coating?this.state.p.coating:'',
                this.state.p.internal_decoration?this.state.p.internal_decoration:'',
                this.state.img[0].image?this.state.img[0].image:'',
                this.state.p.size?this.state.p.size:'',
                this.state.p.price?this.state.p.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.coffinObject)
        }
    }


    fImgBorderChanger(){
        let sImg = document.getElementById("s_img").style
        let fImg = document.getElementById("f_img").style
        let tImg = document.getElementById("t_img").style

        let MainSrc = document.getElementById("main_photo")
        
        fImg.border = "1px solid #896121";
        sImg.border = "1px solid white";
        tImg.border = "1px solid white";
        MainSrc.src = `https://requiem.com.ua${(this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')}`;
        
    }

    sImagBorderChanger(){
        let sImg = document.getElementById("s_img").style
        let fImg = document.getElementById("f_img").style
        let tImg = document.getElementById("t_img").style

        let MainSrc = document.getElementById("main_photo")
        
        fImg.border = "1px solid white";
        sImg.border = "1px solid #896121";
        tImg.border = "1px solid white";
        
        MainSrc.src = `https://requiem.com.ua${(this.state.img? this.state.img[1]?.product ?this.state.img[1].image:'':'')}`;

        
    }

    tImagBorderChanger(){
        let fImg = document.getElementById("f_img").style
        let sImg = document.getElementById("s_img").style
        let tImg = document.getElementById("t_img").style

        let MainSrc = document.getElementById("main_photo")

        fImg.border = "1px solid white";
        sImg.border = "1px solid white";
        tImg.border = "1px solid #896121";

        MainSrc.src = `https://requiem.com.ua${(this.state.img? this.state.img[2]?.product ?this.state.img[2].image:'':'')}`;



    }

    urler = () =>{
        
        const url = new URL(window.location.href);
        const pathParts = url.pathname.split('/');
        const id = pathParts[pathParts.length - 1];
        const id_int = parseInt(id, 10); 
        let arr = [] 


        if(this.state.product !== null){ 
            arr = this.state.product.find(product => product.id === id_int)
            this.setState({p: arr},()=>{console.log(this.state.p)})
        
        }
    }



    async waitCoffin() {
        const response = await getMethods.getProducts()
    
    
        if(response){
          this.setState({
            product: response,
          },()=>{this.urler()})
        }
    
        return response
    
      }

    async waitCandles(){
        const response = await getMethods.getCandles()

        if(response){
            this.setState({
                product: response,
            },()=>{this.urler()})
        }

        return response
    }


    async waitCrosses(){
        const response = await getMethods.getCrosses()


        if(response){
            this.setState({
                product: response,
            },()=>{this.urler()})
        }
        return response
    }

    async waitCoffImg (id){
        const response = await getMethods.getCoffImg(this.state.l_img, id)

        if(response){
            this.setState({
                img: response
            },()=>{this.urler()})
        }

        return response
    }


    async waitBlankets(){
        const response = await getMethods.getBlankets()

        if(response){
            this.setState({
                product: response,
            },()=>{this.urler()})
        }

        return response
    }



    async waitWreaths(){
        const response = await getMethods.getWreaths()

        if(response){
            this.setState({
                product: response,

            },()=>{this.urler()})
        }

        return response

    }
    
    

    async waitClothes(){
        const response = await getMethods.getClothes()
        if(response){
            this.setState({
                product: response,

            },()=>{this.urler()})
        }

        return response
    }



    async foundProduct(){

        if (window.location.pathname.includes('/product/cross')) {
            await this.waitCrosses()
            this.setState({l_img: 'cross'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })

        } 
        else if (window.location.pathname.includes('/product/coffin')) {
            await this.waitCoffin()
            this.setState({l_img: 'coffin'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })

        }
        else if (window.location.pathname.includes('/product/lamp')) {
            await this.waitCandles()
            this.setState({l_img: 'candles'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })

        }

        else if (window.location.pathname.includes('/product/wreath')) {
            await this.waitWreaths()
            this.setState({l_img: 'wreath'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })

        }

        else if (window.location.pathname.includes('/product/blanket')) {
            await this.waitBlankets()
            this.setState({l_img: 'blanket'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })

        }

        else if (window.location.pathname.includes('/product/cloth')) {
            await this.waitClothes()    
            this.setState({l_img: 'clothandshoes'}, async()=>{
                const url = new URL(window.location.href);
                const pathParts = url.pathname.split('/');
                const id = pathParts[pathParts.length - 1];
                const id_int = parseInt(id, 10); 


                await this.waitCoffImg(id_int)
            })
        }

        
    }

    imgShower(){
        console.log(this.state.img)
        if(this.state.img !== null){
            console.log(this.state.img.length)
            console.log('hi')
        }
    }
    
    async componentDidMount(){
        this.imgShower()
        await this.foundProduct()
    }
    
    componentWillUnmount(){
        document.getElementById('pvp_viewer').style.opacity = '0'
        document.body.style.overflowY = 'auto'
        setTimeout(() =>{
            document.getElementById('pvp_viewer').style.display = 'none'
        },200)
    }
    
    
    



    render() {
        return (
            <div className='product_view_pagec'>
                <header className="header_background_selection">
                    <img src={process.env.PUBLIC_URL +"/img/backgroundmain.png"} alt="" />
                </header>
                <div className='product_block'>
                    <div className="left_part_pvp">
                        <div className='image_big_pvp'>
                            <img id='main_photo' onClick={this.viewerOpener} src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt="bruh" />
                        </div>
                        <div id='s_imgs' className="small_images">
                            
                            <img id='f_img' onClick={this.fImgBorderChanger}  src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt="bruh" />
                            {this.state.img && this.state.img[1]?.product ? (
                                <img
                                    id='s_img'
                                    onClick={this.sImagBorderChanger}
                                    src={'https://requiem.com.ua' + this.state.img[1].image}
                                    alt="bruh"
                                />
                                ) : null}
                            {this.state.img && this.state.img[2]?.product ? (
                                <img
                                    id='t_img'
                                    onClick={this.tImagBorderChanger}
                                    src={'https://requiem.com.ua' + this.state.img[2].image}
                                    alt="bruh"
                                />
                                ) : null}

                        </div>
                    </div>

                    <div className='right_part_pvp'>
                        <p className='grob_text'>{this.state.p.name}</p>
                        <div className='info_text_con'>
                            <div className='info_text'>
                                <p className='category_text'>Категорія</p>
                                {this.state.p.internal_decoration && (
                                    <React.Fragment>
                                        <p className='category_text'>Матеріал</p>
                                        <p className='category_text'>Покриття</p>
                                        <p className='category_text'>Внутрішня тканина</p>
                                        <p className='category_text'>Фурнітура</p>
                                        <p className='size_text'>Розмір</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.flower_type && (
                                    <React.Fragment>
                                        <p className='category_text'>матеріал</p>
                                        <p className='category_text'>матеріал каркасу</p>
                                        <p className='category_text'>Квіти</p>
                                        <p className='size_text'>Розмір</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.candle_type && (
                                    <React.Fragment>
                                        <p className="category_text">матеріал</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.blanket_type && (
                                    <React.Fragment>
                                            <p className="category_text">тип</p>
                                            <p className="category_text">оздоблення</p>
                                            <p className="category_text">розмір</p>
                                      
                                    </React.Fragment>
                                )}
                                {this.state.p.description && (
                                    <React.Fragment>
                                        <p className="category_text">опис</p>
                                        <p className="category_text">розмір</p>
                                        <p className="category_text">матеріал</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.cross_coating  && (
                                    <React.Fragment>
                                            <p className="category_text">матеріал </p>
                                            <p className="category_text">покриття</p>
                                    </React.Fragment>
                                )}
                                
                            </div>
                            <div className='second_info_text'>
                                {this.state.p.internal_decoration && (
                                    <React.Fragment>
                                        <p className='white_text'>Домовини</p>
                                        <p className='white_text'>{this.state.p.material?this.state.p.material:"..."}</p>
                                        <p className='white_text'>{this.state.p.coating?this.state.p.coating:"..."}</p>
                                        <p className='white_text'>{this.state.p.internal_decoration?this.state.p.internal_decoration:'...'}</p>
                                        <p className='white_text'>{this.state.p.furnishing?this.state.p.furnishing:'...'}</p>
                                        <p className='white_text'>{this.state.p.size?this.state.p.size:'...'} см (ШхД)</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.flower_type && (
                                    <React.Fragment>
                                        <p className='white_text'>Вінки</p>
                                        <p className="white_text">{this.state.p.material?this.state.p.material:'...'}</p>
                                        <p className="white_text">{this.state.p.frame_material?this.state.p.frame_material:'...'}</p>
                                        <p className="white_text">{this.state.p.flower_type?this.state.p.flower_type:'...'}</p>
                                        <p className="white_text">{this.state.p.size?this.state.p.size:'...'}</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.candle_type && (
                                    <React.Fragment>
                                        <p className='white_text'>Лампадки та свічки</p>
                                        <p className="white_text">{this.state.p.candle_type?this.state.p.candle_type:"..."}</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.blanket_type && (
                                    <React.Fragment>
                                        <p className='white_text'>Покривала</p>
                                        <p className="white_text">{this.state.p.blanket_type?this.state.p.blanket_type:"..."}</p>
                                        <p className="white_text">{this.state.p.blanket_decoration?this.state.p.blanket_decoration:"..."}</p>
                                        <p className="white_text">{this.state.p.blanket_size?this.state.p.blanket_size:"..."}</p>
                                      
                                    </React.Fragment>
                                )}
                                {this.state.p.description && (
                                    <React.Fragment>
                                        <p className='white_text'>Одяг та взуття</p>
                                        <p className="white_text">{this.state.p.description?this.state.p.description:"..."}</p>
                                        <p className="white_text">{this.state.p.size?this.state.p.size:"..."}</p>
                                        <p className="white_text">{this.state.p.material?this.state.p.material:"..."}</p>
                                    </React.Fragment>
                                )}
                                {this.state.p.cross_coating  && (
                                    <React.Fragment>
                                        <p className='white_text'>Хрести</p>
                                        <p className="white_text">{this.state.p.material?this.state.p.material:"..."}</p>
                                        <p className="white_text">{this.state.p.cross_coating?this.state.p.cross_coating:"..."}</p>
                                    </React.Fragment>
                                )}
                               
                            </div>

                        </div>
                        <div className='price_container'>
                              <p className='price_text'>Ціна від</p>
                              <p className='price_number'>{this.state.p.price}</p>
                              
                        </div>
                    </div>

                    <div className='buy_button_con'>
                        <button onClick={this.addToCartLocalView} className="buy_button">Придбати</button>
                    </div>
                    
                </div>

            <PageImageViewer photos = {this.state.img}/>
            <FooterUp/>
            <FooterBot/>
            </div>
        )
    }
}

