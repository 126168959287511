import React from 'react'
import { NavLink } from 'react-router-dom'
import "./dist/clothescard.css"

import getMethods from '../../services/getMethods'


export class ClothesCard extends React.Component {
    constructor(props){
        super(props)
        this.createObject = this.createObject.bind(this)
        this.waitCoffImg = this.waitCoffImg.bind(this)


        this.state = {
            currentObject : {
                id:'',
                name : '',
                material:'',
                size:'',
                price:'',
                img: null,

            }
        }

    }


    async waitCoffImg (){
        const response = await getMethods.getCoffImg("clothandshoes",this.props.cloth.id)

        if(response){
            this.setState({
                img: response
            })
        }

        return response
    }



    updateFieldValue = (key, value) => {
        this.setState(prevState => ({
            currentObject: {
            ...prevState.currentObject,
            [key]: value
          }
        }));
      }

    createObject(id,name,description,img,size,price){
        this.updateFieldValue('id', id)
        this.updateFieldValue('name', name)
        this.updateFieldValue('description', description)
        this.updateFieldValue('img', img)
        this.updateFieldValue('size', size)
        this.updateFieldValue('price', price)

    }


    modalViewer = (local_text) => {
        document.getElementById('modal_w').style.transform = 'translatey(0vh)'
        document.getElementById('modal_w').innerText = local_text

        setTimeout(()=> {
            if( document.getElementById('modal_w'))
                document.getElementById('modal_w').style.transform = 'translatey(11vh)'
        }, 2000)
    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }

    id_cheker = () =>{
        const objectExists = this.props.cart.some(item => item.id === this.props.cloth.id);
        return objectExists

    }
    addToCartLocal = async (e)=>{
        e.preventDefault();
        await this.createObject(
                    this.props.cloth.id ? this.props.cloth.id:'',
                    this.props.cloth.name ? this.props.cloth.name:'',
                    this.props.cloth.description? this.props.cloth.description:'',
                    this.state.img[0].image?this.state.img[0].image:'',
                    this.props.cloth.size?this.props.cloth.size:'',
                    this.props.cloth.price?this.props.cloth.price:'',

                    )
        this.props.addToCart(this.state.currentObject)
        this.soundBoard()
        if(!this.id_cheker()){
            this.modalViewer("Товар додано у корзину")
        }else{
            this.modalViewer("Повторно додано у корзину ")
            
        }



    }


    async componentDidMount(){
        await this.waitCoffImg()


        this.props.setLink('cloth')
    }



    render(){
        return(
            <NavLink to={`/product/cloth/${this.props.cloth.id}`} className="products_card">
                <img className='p_img' src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt={this.props.cloth.name} title={this.props.cloth.name} />

                <div className="p_card_title">{this.props.cloth.name ? this.props.cloth.name:''}</div>
                <div className="category">
                    <p className='m_title'>{this.props.cloth.description}</p>
                </div>
                <div className="category">
                    <p className='m_title'>розмір</p>
                    <p className='c_body'>{this.props.cloth.size}</p>
                </div>
                <div onClick={this.addToCartLocal} className="buy_p_btn">Придбати</div>
            </NavLink>
        )
    }
}