import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { ClothesCard } from "../../components/ClothesCard/clothescard";
import "./dist/ClothesPage.css"
import { Helmet } from 'react-helmet'


import getMethods from "../../services/getMethods";

export class ClothesPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clothes:sessionStorage.getItem("clothes")?JSON.parse(sessionStorage.getItem("clothes")) : null,
            local_clothes:sessionStorage.getItem("clothes")?JSON.parse(sessionStorage.getItem("clothes")) : null,

        }


        this.waitClothes = this.waitClothes.bind(this)
        this.clothes_capture = this.clothes_capture.bind(this)

    }



    async waitClothes(){
        const response = await getMethods.getClothes()
        if(response){
            this.setState({
                clothes: response,
                local_clothes:response

            },()=>{
                sessionStorage.setItem('clothes',JSON.stringify(response))
            })
        }

        return response
    }

    clothes_capture(local_clothes){
        this.setState({local_clothes : local_clothes})
    }

    async componentDidMount(){
        try {
            if(!this.state.clothes)
                await this.waitClothes()
        } catch (error) {
            return 0
        }
        
    }



    render(){
        return(
            <div className="products_page">
                <Helmet>
                    <title>Одяг та взуття. Магазин ритуальних послуг Реквієм у Львові</title>
                    <meta name="description" content="Ритуальний одяг та взуття у Львові. Ритуальний одяг та взуття різних розмірів та кольорів у нашому магазині ритуальних послуг Реквієм. Ритуальний одяг та взуття у Львові. Одяг та взуття для похорону у Львові та Львівській області. "/>
                    <meta property="og:title" content="Одяг та взуття. Магазин ритуальних послуг Реквієм у Львові"/>
                    <meta property="og:description" content="Ритуальний одяг та взуття у Львові. Ритуальний одяг та взуття різних розмірів та кольорів у нашому магазині ритуальних послуг Реквієм. Ритуальний одяг та взуття у Львові. Одяг та взуття для похорону у Львові та Львівській області."/>
                </Helmet>
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = "background" />
                </header>
                <h1 className="product_view_page_title">Ритуальний одяг</h1>
                {/* <p className="description_w_text">
                    <h2 className="inline-heading">Ритуальний одяг</h2> має важливе символічне значення. Він відображає <h2 className="inline-heading">останній образ покійного</h2> на землі і може включати в себе <h2 className="inline-heading">вбрання, аксесуари та прикраси.</h2> Ритуальний одяг підбирається з урахуванням віку, соціального статусу та інших культурних аспектів. Це допомагає надати покійникові гідний вигляд і висловити повагу до його пам'яті.
                </p> */}
                <div className="product_view_page">
                    
                    <div className="products_view">
                        {this.state.clothes !== null? (
                            this.state.local_clothes.map((cloth,index) =>(
                                <ClothesCard setLink = {this.props.setLink} addToCart = {this.props.addToCart} cart={this.props.cart}  cloth = {cloth} key={index}/>
                            ))
                        ):'f'}


                    </div>
                </div>

                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}