import React from "react";
import "./dist/pnb.css";
import { NavLink } from "react-router-dom";


export class ProductNavBar extends React.Component {
    constructor(props){
        super(props)
        this.img_context_changer = this.img_context_changer.bind(this)

        this.state = {
            local_cart : this.props.cart
        }
    }
    
    img_context_changer(){
        let my_img = document.getElementById('second_header_button')
        let all_block = document.getElementById('pnb')

        if(window.location.pathname === '/cart' && window.innerWidth < 426){
            all_block.style.display = 'none'
        }
        else{
            all_block.style.display = 'flex'
        }


        if(window.location.pathname !== `/product/${this.props.link}/:id` ){
            my_img.style.display = 'none'

        }else{
            my_img.style.display = 'block'
            if(window.innerWidth > 426){
                //работает не трогай - сломаешь ,дурак
                let m_input = document.getElementById('m_input')
                m_input.style.width = '60%'
            }
        }
    }

    global_search = () => {
        let search 
        if(window.innerWidth > 770){
            search = document.getElementById("search_input").value
        }else{
            search = document.getElementById("m_input").value
        }
        if(window.location.pathname === '/cart'){
            let new_cart = this.props.cart.filter((cart_el)=>{
                let fullfilter =  
                    cart_el.name?.includes(search) || 
                    cart_el.material?.includes(search) ||
                    cart_el.coating?.includes(search) ||
                    cart_el.price?.includes(search) ||
                    cart_el.size?.includes(search) ||
                    cart_el.internal_decoration?.includes(search) ||
                    cart_el.furnishing?.includes(search) || 
                    cart_el.frame_material?.includes(search) || 
                    cart_el.flower_type?.includes(search) || 
                    cart_el.cross_coating?.includes(search) || 
                    cart_el.candle_type?.includes(search) || 
                    cart_el.blanket_type?.includes(search) || 
                    cart_el.blanket_size?.includes(search) || 
                    cart_el.description?.includes(search) ||
                    cart_el.blanket_decoration?.includes(search)
                return fullfilter
                })
            this.props.cart_keeper(new_cart)
        }else if(window.location.pathname === '/coffin'){
            let new_coffin = this.props.coffin.filter((coffin_el)=>{
                let fullfilter = 
                    coffin_el.name?.includes(search)||
                    coffin_el.coating?.includes(search)||
                    coffin_el.furnishing?.includes(search)||
                    coffin_el.internal_decoration?.includes(search)||
                    coffin_el.material?.includes(search)||
                    coffin_el.size?.includes(search)
                return fullfilter
            })
            this.props.coffin_capture(new_coffin)

        }
        else if(window.location.pathname === '/crosses'){
            let new_crosses = this.props.crosses.filter((crosses_el)=>{
                let fullfilter = 
                    crosses_el.name?.includes(search)||
                    crosses_el.material?.includes(search)||
                    crosses_el.cross_coating?.includes(search)
                return fullfilter
            })
            this.props.crosses_capture(new_crosses)

        }
        else if(window.location.pathname === '/lampscandles'){
            let new_candles = this.props.candels.filter((candels_el)=>{
                let fullfilter = 
                    candels_el.name?.includes(search)||
                    candels_el.candle_type?.includes(search)
                return fullfilter
            })
            this.props.candels_capture(new_candles)

        }
        else if(window.location.pathname === '/veils'){
            let new_blanket = this.props.blankets.filter((veils_el)=>{
                let fullfilter = 
                    veils_el.name?.includes(search)||
                    veils_el.blanket_type?.includes(search)||
                    veils_el.blanket_size?.includes(search)||
                    veils_el.blanket_decoration?.includes(search)
                return fullfilter
            })
            this.props.blankets_capture(new_blanket)

        }
        else if(window.location.pathname === '/wreaths'){
            let new_wreaths = this.props.wrearth.filter((wreaths_el)=>{
                let fullfilter = 
                    wreaths_el.name?.includes(search)||
                    wreaths_el.material?.includes(search)||
                    wreaths_el.frame_material?.includes(search)||
                    wreaths_el.flower_type?.includes(search)||
                    wreaths_el.size?.includes(search)
                return fullfilter
            })
            this.props.wrearth_capture(new_wreaths)

        }
        else if(window.location.pathname === '/clothes'){
            let new_clothes = this.props.clothes.filter((clothes_el)=>{
                let fullfilter = 
                    clothes_el.name?.includes(search)||
                    clothes_el.material?.includes(search)||
                    clothes_el.size?.includes(search)
                return fullfilter
            })
            this.props.clothes_capture(new_clothes)

        }
    }

    


    componentDidMount(){
        this.img_context_changer()
    }

    render() {
        return (
            <div id="pnb" className="product_nav_bar">
                <NavLink to='/'  className="back_btn">
                    <img id="second_header_button" src={process.env.PUBLIC_URL + '/img/back_btn.svg'} alt="back_btn" title = "back_btn"/>
                </NavLink>
                <div className="search_block">
                    <div className="input_s_block">
                        <input id="search_input" className="search_input" placeholder="Я шукаю..." type="text" />
                        <button onClick={this.global_search} >знайти </button>
                    </div>
                    <img className="search_btn" src={process.env.PUBLIC_URL + '/img/search_btn.svg'} alt="Пошук" title = "Пошук"/>
                </div>

                <div id="m_search_block" className="m_search_blcok">
                    <input id="m_input" className="m_input" placeholder="Я шукаю..." type="text" />
                    <button onClick={this.global_search} id="m_btn" className="m_btn">знайти</button>
                </div>


            </div>
        )
    }
}