import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import "./dist/CrossesPage.css"
import { CrosseCard } from "../../components/CrossesCard/crossescard";
import { Helmet } from 'react-helmet';


import getMethods from "../../services/getMethods";

export class CrossesPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            crosses: sessionStorage.getItem("crosses")?JSON.parse(sessionStorage.getItem("crosses")) : null,
            local_crosses:sessionStorage.getItem("crosses")?JSON.parse(sessionStorage.getItem("crosses")) : null
        }

        this.waitCrosses = this.waitCrosses.bind(this)
        this.crosses_capture = this.crosses_capture.bind(this)

    }



    async waitCrosses(){
        const response = await getMethods.getCrosses()


        if(response){
            this.setState({
                crosses: response,
                local_crosses:response
            },()=>{
                sessionStorage.setItem('crosses',JSON.stringify(response))
            })
        }
        return response
    }

    crosses_capture(local_crosses){
        this.setState({local_crosses : local_crosses})
    }

    async componentDidMount(){
        try {
            if(!this.state.crosses)
                await this.waitCrosses()
            
        } catch (error) {
            return 0
        }
        
    }





    render(){
        return(
            <div className="products_page">

                <Helmet>
                    <title>Хрести. Магазин ритуальних послуг Реквієм у Львові.</title>
                    <meta name="description" content="Хрести ритуальні для похорону. Широкий асортимент хрестів та розп'яття у магазині ритуальних послуг Реквієм у Львові. Хрести для похорону у Львові. " />
                    <meta property="og:title" content="Хрести. Магазин ритуальних послуг Реквієм у Львові."/>
                    <meta property="og:description" content="Хрести ритуальні для похорону. Широкий асортимент хрестів та розп'яття у магазині ритуальних послуг Реквієм у Львові. Хрести для похорону у Львові."/>
                </Helmet>

                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = 'background' />
                </header>
                <h1 className="product_view_page_title">Ритуальні хрести</h1>
                {/* <p className="description_w_text">
                    <h2 className="inline-heading">Ритуальний хрест</h2> встановлюється на могилі після похорону і має <h2 className="inline-heading">захищати покійного</h2> від негативних впливів. Хрест є символом <h2 className="inline-heading">духовності і віри,</h2> а також виявом пам'яті про покійного. Він може бути виготовлений з різних матеріалів, таких як <h2 className="inline-heading">дерево, ДСП чи навіть граніт.</h2> Різноманітність дизайнів дозволяє вибрати хрест, який відповідає вподобанням родини та покійного.
                </p> */}
                <div className="product_view_page">
                    
                    <div className="products_view">
                    {this.state.crosses !== null? (
                            this.state.local_crosses.map((cross,index) =>(
                                <CrosseCard setLink = {this.props.setLink} cart = {this.props.cart} addToCart = {this.props.addToCart} crosses={this.state.crosses} cross = {cross} key={index}/>
                            ))
                        ):'f'}



                    </div>
                </div>
                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}