import React from 'react'
import { NavLink } from 'react-router-dom'
import "./dist/veilscard.css"


import getMethods from '../../services/getMethods'



export class VeilsCard extends React.Component {


    constructor(props){
        super(props)
        this.createObject = this.createObject.bind(this)
        this.waitCoffImg = this.waitCoffImg.bind(this)




        this.state = {
            currentObject : {
                
                id:'',
                name : '',
                blanket_type:'',
                blanket_size:'',
                blanket_decoration:'',
                price:'',
                img: null,

            }
        }

    }




    


    async waitCoffImg (){
        const response = await getMethods.getCoffImg("blanket",this.props.blanket.id)

        if(response){
            this.setState({
                img: response
            })
        }

        return response
    }



    updateFieldValue = (key, value) => {
        this.setState(prevState => ({
            currentObject: {
            ...prevState.currentObject,
            [key]: value
          }
        }));
      }
    createObject( id,name,blanket_type,blanket_size,blanket_decoration,price,img){
        this.updateFieldValue('id', id)
        this.updateFieldValue('name', name)
        this.updateFieldValue('blanket_type', blanket_type)
        this.updateFieldValue('blanket_size', blanket_size)
        this.updateFieldValue('blanket_decoration', blanket_decoration)
        this.updateFieldValue('img', img)
        this.updateFieldValue('price', price)

    }


    modalViewer = (local_text) => {
        document.getElementById('modal_w').style.transform = 'translatey(0vh)'
        document.getElementById('modal_w').innerText = local_text

        setTimeout(()=> {
            if( document.getElementById('modal_w'))
                document.getElementById('modal_w').style.transform = 'translatey(11vh)'
        }, 2000)
    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }
    id_cheker = () =>{
        const objectExists = this.props.cart.some(item => item.id === this.props.blanket.id);
        return objectExists

    }
    
    addToCartLocal = async (e)=>{
        e.preventDefault();
        await this.createObject(
            this.props.blanket.id ? this.props.blanket.id:'',
            this.props.blanket.name ? this.props.blanket.name:'',
            this.props.blanket.blanket_type? this.props.blanket.blanket_type:'',
            this.props.blanket.blanket_size? this.props.blanket.blanket_size:'',
            this.props.blanket.blanket_decoration? this.props.blanket.blanket_decoration:'',
            this.props.blanket.price?this.props.blanket.price:'',
            this.state.img[0].image?this.state.img[0].image:'',
            )
            this.soundBoard()
        this.props.addToCart(this.state.currentObject)
        if(!this.id_cheker()){
            this.modalViewer("Товар додано у корзину")
        }else{
            this.modalViewer("Повторно додано у корзину ")
            
        }
        



    }


    async componentDidMount(){
        await this.waitCoffImg()


        this.props.setLink('blanket')
        

    }


    render(){
        return(
            <NavLink to={`/product/blanket/${this.props.blanket.id}`} className="products_card">
                <img className='p_img' src={'https://requiem.com.ua' + (this.state.img? this.state.img[0]?.product ?this.state.img[0].image:'':'')} alt = {this.props.blanket.name} />
                <div className="p_card_title">{this.props.blanket.name ? this.props.blanket.name:''}</div>
                <div className="category">
                    <p className='m_title'>матеріал</p>
                    <p className='c_body'>{this.props.blanket.blanket_type}</p>
                </div>
                <div className="category">
                    <p className='m_title'>покриття</p>
                    <p className='c_body'>{this.props.blanket.blanket_decoration}</p>
                </div>
                <div className="category">
                    <p className='m_title'>розмір</p>
                    <p className='c_body'>{this.props.blanket.blanket_size} см</p>
                </div>
                <div onClick={this.addToCartLocal} className="buy_p_btn">Придбати</div>
            </NavLink>
        )
    }
}