import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { LampCard } from "../../components/lampCard/lampcard";
import { Helmet } from 'react-helmet'
import "./dist/LampsAndCandlesPage.css"


import getMethods from "../../services/getMethods";

export class LampsAndCandlesPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            candels:  sessionStorage.getItem("candels")?JSON.parse(sessionStorage.getItem("candels")) : null,
            local_candels:  sessionStorage.getItem("candels")?JSON.parse(sessionStorage.getItem("candels")) : null,
        }


        this.waitCandles = this.waitCandles.bind(this)
        this.candels_capture = this.candels_capture.bind(this)

    }



    async waitCandles(){
        const response = await getMethods.getCandles()

        if(response){
            this.setState({
                candels: response,
                local_candels:response
            },()=>{
                sessionStorage.setItem('candels',JSON.stringify(response))
            })
        }

        return response
    }

    candels_capture(local_candels){
        this.setState({local_candels : local_candels})
    }


    async componentDidMount(){
        try {
            if(this.state.candels === null)
                await this.waitCandles()
        } catch (error) {
            return 0
        }
    }

    render(){
        return(
            <div className="products_page">
                <Helmet>
                    <title>Лампадки та свічки. Магазин ритуальних послуг Реквієм у Львові</title>
                    <meta name="description" content="Лампадки та свічки у Львові. Широкий асортимент різноманітних ритуальних лампадок та свічок у нашому магазині ритуальних послуг Реквієм. Ритуальні лампадки та ритуальні свічки у Львові. Свічки та лампадки у Львові та Львівській області. "/>
                    <meta property="og:title" content="Лампадки та свічки. Магазин ритуальних послуг Реквієм у Львові"/>
                    <meta property="og:description" content="Лампадки та свічки у Львові. Широкий асортимент різноманітних ритуальних лампадок та свічок у нашому магазині ритуальних послуг Реквієм. Ритуальні лампадки та ритуальні свічки у Львові. Свічки та лампадки у Львові та Львівській області."/>
                </Helmet>
                
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = 'background'/>
                </header>
                <h2 className="product_view_page_title">Лампадки та свічки</h2>
                <div className="product_view_page">
                    
                    <div className="products_view">
                    {this.state.candels !== null? (
                            this.state.local_candels.map((candel,index) =>(
                                <LampCard setLink= {this.props.setLink} addToCart = {this.props.addToCart} cart={this.props.cart} candel = {candel} key={index}/>                            
                            ))
                        ):'f'}


                    </div>
                </div>
                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}