
import React , {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import getMethods from './services/getMethods';


import './App.css';
import Nav from './components/NavBar/nav';
import { Cart } from './pages/cart/cart';
import { Contacts } from './pages/Contacts/Contacts';
import { Main } from './pages/main/main';
import {ProductViewPage} from './pages/ProductViewPage/pvp';
import { ProductsPage } from './pages/ProductsPage/pg';
import { PSelection } from './pages/ProductSelection/pselection';
import { AdminPanel } from './pages/AdminPanel/ad';
import ThanksForm from './components/ThanksForm/thanksform';
import { CrossesPage } from './pages/CrossesPage/CrossesPage';
import { WreathsPage } from './pages/WreathsPage/WreathsPage';
import { VeilsPage } from './pages/VeilsPage/VeilsPage';
import { LampsAndCandlesPage } from './pages/LampsAndCandlesPage/LampsAndCandlesPage';
import { LiveWreathsPage } from './pages/LiveWreathsPage/LiveWreathsPage';
import { ClothesPage } from './pages/ClothesPage/ClothesPage';
import { LoginPage } from './pages/LoginPage/LoginPage';


import { NotFound } from './pages/NotFoundPage/notfound';



class App extends Component {
  constructor(props){
    super(props)

    this.state = {
      products: null,
      cart: sessionStorage.getItem('cart')?JSON.parse(sessionStorage.getItem('cart')): [],
      local_cart: sessionStorage.getItem('cart')?JSON.parse(sessionStorage.getItem('cart')): [],
      link: '',
      name:'',
      phone:'',
    }

    this.scrollKeeper = this.scrollKeeper.bind(this) 
    this.addToCart = this.addToCart.bind(this)
    this.cart_keeper = this.cart_keeper.bind(this)
    this.linkSet = this.linkSet.bind(this)

    //wait_send_or_call
    this.wait_send_or_call = this.wait_send_or_call.bind(this)
  }


  nameBack = (name)=>{
    this.setState({name:name})
  }
  phoneBack = (phone)=>{
    this.setState({phone:phone})
  }
  async wait_send_or_call(){
    if(this.state.name !== '' && this.state.phone !== ''){
      if(this.state.cart !== undefined){
        const responce = await getMethods.formSender(
          this.state.name,
          this.state.phone,
          null,
          JSON.stringify(this.state.cart)
        )
        this.anigilation()
        return responce
      }else{
        const responce = await getMethods.formSender(
          this.state.name,
          this.state.phone,
          null,
          null
          )
          return responce
      }
        
    }
  }



  linkSet(link){
    this.setState({link: link})
  }

  addToCart(new_item) {
    this.setState(prevState => ({
      cart: [...prevState.cart, new_item],
      local_cart: [...prevState.local_cart, new_item]
    }),()=>{
      sessionStorage.setItem('cart', JSON.stringify(this.state.cart))
    });

  }

  removeFromCart = (indexToRemove) => {
    this.setState(prevState => ({
        cart: prevState.cart.filter((_, index) => index !== indexToRemove),
        local_cart: prevState.local_cart.filter((_, index) => index !== indexToRemove)
    }),
    ()=>{
      sessionStorage.setItem('cart', JSON.stringify(this.state.cart))
    });
  }
  anigilation = () => {
      this.setState({
        cart: [],
        local_cart: [] 
      }, () => {
        sessionStorage.removeItem('cart'); // Удаляем из сессии, если нужно
      });
  }

  scrollKeeper(){
    if(window.location.pathname === "/"){
      if(window.scrollY < 75 && window.location.pathname === "/" && window.innerWidth > 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "87%"

      }
      else if(window.scrollY>1080 && window.scrollY < 1770 && window.innerHeight < 700 && window.location.pathname === "/" && window.innerWidth > 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "50%"
      }
      else if(window.scrollY>1501 && window.scrollY < 2400 && window.innerHeight > 720 && window.innerHeight<1000 && window.location.pathname === "/" && window.innerWidth > 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "50%"
      }
  
      else if(window.scrollY>1152 && window.scrollY < 1646 && window.innerHeight > 720 && window.innerHeight <1000 && window.location.pathname === "/" && window.innerWidth > 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "50%"
  
      }
      else if(window.scrollY>1200 && window.scrollY < 1972 && window.innerHeight > 720 && window.innerHeight <1000 && window.location.pathname === "/" && window.innerWidth < 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "50%"
  
      }
      else if(window.scrollY<222 && window.location.pathname === "/" && window.innerWidth < 426){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "50%"
  
      }
      else{
        document.getElementById("navbar").style.background = "#8D6321"
        document.getElementById("navbar").style.opacity = "100%"
  
      }
    }
    else{
      if(window.scrollY < 75){
        document.getElementById("navbar").style.background = "black"
        document.getElementById("navbar").style.opacity = "87%"
      }
      else{
        document.getElementById("navbar").style.background = "#8D6321"
        document.getElementById("navbar").style.opacity = "100%"  
      }

    }
  }

  cart_keeper(local_cart) {
    this.setState({ local_cart: local_cart })
  }

  componentDidMount(){
    window.addEventListener('scroll', this.scrollKeeper); 
    
    window.addEventListener("keydown",(e)=>{
        if(e.key === 'Tab'){
            e.preventDefault();
        }
    },false)


    if(window.location.pathname ===  "/login"){
      document.getElementById('navbar').style.display = 'none'

    }
    else{
      document.getElementById('navbar').style.display = 'flex'
    }
  }

  
  
  

  render() {
    return(
      <div>
        <Router>
        <Nav cart = {this.state.cart} />
          <Routes>
            <Route path='/clothes' element = {<ClothesPage setLink = {this.linkSet} cart = {this.state.cart}  addToCart = {this.addToCart}/>}/>
            <Route path='/livewreaths' element = {<LiveWreathsPage/>}/>
            <Route path='/contacts' element = {<Contacts />}/>
            <Route path='/' element = {<Main addToCart={this.addToCart} phoneBack={this.phoneBack} nameBack={this.nameBack} wait_send_or_call = {this.wait_send_or_call} />}/>
            <Route path={`/product/${this.state.link}/:id`} element = {<ProductViewPage link = {this.state.link} addToCart={this.addToCart}/>}/>
            <Route path='/coffin' element = {<ProductsPage setLink = {this.linkSet} cart = {this.state.cart}  addToCart = {this.addToCart}/>}/> 
            <Route path='/select_category' element= {<PSelection/>}/>
            <Route path='/cart' element= {<Cart phoneBack={this.phoneBack} nameBack={this.nameBack} wait_send_or_call = {this.wait_send_or_call} local_cart={this.state.local_cart} cart_keeper={this.cart_keeper} removeFromCart={this.removeFromCart} cart = {this.state.cart}/>}/>   
            <Route path='/admin' element={<AdminPanel/>}/>
            <Route path='/thankYou' element={<ThanksForm />}/>
            <Route path='/crosses' element = {<CrossesPage setLink = {this.linkSet}  cart = {this.state.cart} addToCart = {this.addToCart}/>}/>
            <Route path='/wreaths' element = {<WreathsPage setLink = {this.linkSet} cart = {this.state.cart} addToCart = {this.addToCart}/>}/>
            <Route path='/veils' element = {<VeilsPage setLink = {this.linkSet} cart = {this.state.cart}  addToCart = {this.addToCart}/>}/>
            <Route path='/lampscandles' element={<LampsAndCandlesPage setLink = {this.linkSet} cart = {this.state.cart} addToCart = {this.addToCart}/>}/>
            <Route path='/login' element={<LoginPage/>}/> 
            <Route path='*' element={<NotFound/>}/>
          </Routes>
        </Router>

      </div>
    )
  }

}

export default App
