import React from "react";


import getMethods from "../../services/getMethods";


export class SCarouselCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            scarousel_img : "",
            coffinObject:{
                id:'',
                name : '',
                material:'',
                coating:'',
                internal_decoration:'',
                size:'',
                price:'',
                               
            },
            lambObject:{
                id:'',
                name : '',
                candle_type:'',
                price:'',
                             
            },
            blankedObject:{
                id:'',
                name : '',
                blanket_type:'',
                blanket_size:'',
                blanket_decoration:'',
                price:'',
                
            },
            crossObject:{
                id: '',
                name : '',
                material:'',
                cross_coating:'',
                price:'',
                
                id: ''
            },
            wreathsObject:{
                id:'',
                name : '',
                material:'',
                frame_material:'',
                size:'',
                flower_type:"",
                price:'',
                
                
            },
            clothesObject:{
                id:'',
                name : '',
                material:'',
                size:'',
                price:'',
                
                
            },
        }
    }
    updateCoffin = (key, value) => {
        this.setState(prevState => ({
            coffinObject: {
            ...prevState.coffinObject,
            [key]: value
            }
        }));
        }
    updateLambs = (key, value) => {
        this.setState(prevState => ({
            lambObject: {
            ...prevState.lambObject,
            [key]: value
            }
        }));
        }
    updateBlankets = (key, value) => {
        this.setState(prevState => ({
            blankedObject: {
            ...prevState.blankedObject,
            [key]: value
            }
        }));
        }
    updateCrosses = (key, value) => {
        this.setState(prevState => ({
            crossObject: {
            ...prevState.crossObject,
            [key]: value
            }
        }));
        }
    updateWreaths = (key, value) => {
            this.setState(prevState => ({
                wreathsObject: {
                ...prevState.wreathsObject,
                [key]: value
                }
        }));
        }
    updateClothes = (key, value) => {
        this.setState(prevState => ({
            clothesObject: {
            ...prevState.clothesObject,
            [key]: value
            }
    }));
    }

    createCoffin(id,name,material,coating,internal_decoration,img,size,price){
        this.updateCoffin('id', id)
        this.updateCoffin('name', name)
        this.updateCoffin('material', material)
        this.updateCoffin('coating', coating)
        this.updateCoffin('internal_decoration', internal_decoration)
        this.updateCoffin('img', img)
        this.updateCoffin('size', size)
        this.updateCoffin('price', price)
    }
    createLambs(id,name,candle_type,img,price){
        this.updateLambs('id', id)
        this.updateLambs('name', name)
        this.updateLambs('candle_type', candle_type)
        this.updateLambs('img', img)
        this.updateLambs('price', price)

    }
    createBlanket( id,name,blanket_type,blanket_size,blanket_decoration,price,img){
        this.updateBlankets('id', id)
        this.updateBlankets('name', name)
        this.updateBlankets('blanket_type', blanket_type)
        this.updateBlankets('blanket_size', blanket_size)
        this.updateBlankets('blanket_decoration', blanket_decoration)
        this.updateBlankets('img', img)
        this.updateBlankets('price', price)

    }
    createCrosses(id,name ,material,cross_coating,price,img){
        this.updateCrosses('id', id)
        this.updateCrosses('name', name)
        this.updateCrosses('material', material)
        this.updateCrosses('cross_coating', cross_coating)
        this.updateCrosses('price', price)
        this.updateCrosses('img', img)
    }
    createWreathes(id,name ,material,frame_material,size,flower_type,price,img){
        this.updateWreaths('id', id)
        this.updateWreaths('name', name)
        this.updateWreaths('material', material)
        this.updateWreaths('frame_material', frame_material)
        this.updateWreaths('flower_type', flower_type)
        this.updateWreaths('size', size)
        this.updateWreaths('price', price)
        this.updateWreaths('img', img)
    }
    createClothes(id,name,description,img,size,price){
        this.updateClothes('id', id)
        this.updateClothes('name', name)
        this.updateClothes('description', description)
        this.updateClothes('img', img)
        this.updateClothes('size', size)
        this.updateClothes('price', price)

    }

    soundBoard = () =>{
        const sound = new Audio(process.env.PUBLIC_URL + '/bell.mp3')
        sound.play()
    }
    addToCardLocal = async(e)=>{
        e.preventDefault();
        if(this.props.sproduct.blanket_type){
            await this.createBlanket(
                this.props.sproduct.id ? this.props.sproduct.id:'',
                this.props.sproduct.name ? this.props.sproduct.name:'',
                this.props.sproduct.blanket_type? this.props.sproduct.blanket_type:'',
                this.props.sproduct.blanket_size? this.props.sproduct.blanket_size:'',
                this.props.sproduct.blanket_decoration? this.props.sproduct.blanket_decoration:'',
                this.props.sproduct.price?this.props.sproduct.price:'',
                this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
                )
                this.soundBoard()
                this.props.addToCart(this.state.blankedObject)
        }else if( this.props.sproduct.description){
            await this.createClothes(
                 this.props.sproduct.id ?  this.props.sproduct.id:'',
                 this.props.sproduct.name ?  this.props.sproduct.name:'',
                 this.props.sproduct.description?  this.props.sproduct.description:'',
                this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
                 this.props.sproduct.size? this.props.sproduct.size:'',
                 this.props.sproduct.price? this.props.sproduct.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.clothesObject)
        }
        else if( this.props.sproduct.flower_type){
            await this.createWreathes(
                 this.props.sproduct.id ?  this.props.sproduct.id:'',
                 this.props.sproduct.name ?  this.props.sproduct.name:'',
                 this.props.sproduct.material?  this.props.sproduct.material:'',
                 this.props.sproduct.frame_material?  this.props.sproduct.frame_material:'',
                 this.props.sproduct.size?  this.props.sproduct.size:'',
                 this.props.sproduct.flower_type?  this.props.sproduct.flower_type:'',
                 this.props.sproduct.price? this.props.sproduct.price:'',
                this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.wreathsObject)
        }
        else if( this.props.sproduct.cross_coating){
            await this.createCrosses(
                     this.props.sproduct.id ?  this.props.sproduct.id:'',
                     this.props.sproduct.name ?  this.props.sproduct.name:'',
                     this.props.sproduct.material?  this.props.sproduct.material:'',
                     this.props.sproduct.cross_coating? this.props.sproduct.cross_coating:'',
                     this.props.sproduct.price? this.props.sproduct.price:'',
                    this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.crossObject)
        }
        else if( this.props.sproduct.candle_type){
            await this.createLambs(
                 this.props.sproduct.id ?  this.props.sproduct.id:'',
                 this.props.sproduct.name ?  this.props.sproduct.name:'',
                 this.props.sproduct.candle_type?  this.props.sproduct.candle_type:'',
                this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
                 this.props.sproduct.price? this.props.sproduct.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.lambObject)
        }
        else if( this.props.sproduct.internal_decoration){
            await this.createCoffin(
                 this.props.sproduct.id ?  this.props.sproduct.id:'',
                 this.props.sproduct.name ?  this.props.sproduct.name:'',
                 this.props.sproduct.material?  this.props.sproduct.material:'',
                 this.props.sproduct.coating? this.props.sproduct.coating:'',
                 this.props.sproduct.internal_decoration? this.props.sproduct.internal_decoration:'',
                this.state.scarousel_img[0].image?this.state.scarousel_img[0].image:'',
                 this.props.sproduct.size? this.props.sproduct.size:'',
                 this.props.sproduct.price? this.props.sproduct.price:'',
            )
            this.soundBoard()
            this.props.addToCart(this.state.coffinObject)
        }
    }
    


    waitCoffImg =  async() => {
        
        const response = await getMethods.getCoffImg(this.props.stateType,this.props.sproduct? this.props.sproduct.id:'')

        if(response){

                this.setState({
                    scarousel_img: response
                })
            
        }
        return response
    }
    componentDidMount(){
            this.waitCoffImg()
        
    }
    
    async componentDidUpdate(prevProps,prevState){
        if(this.props.stateType !== prevProps.stateType || this.props.sproduct !== prevProps.sproduct){
            setTimeout(async ()=>{
                await this.waitCoffImg()
            },50)
        }   
    }


    render(){
        return(
            <div className="product_card">
                <div className='img_card_con_carousel'>
                    <img className='img_card_carousel' src={"https://requiem.com.ua"+ (this.state.scarousel_img[0]?.image)} alt={this.props.sproduct.name} title={this.props.sproduct.name} />
                </div>
                <span>{this.props.sproduct.name}</span>
                <div className="price">
                    <div onClick={this.addToCardLocal} className="buy_btn_d">
                        купити
                    </div>
                </div>
            </div>
        )
    }
}