import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { VeilsCard } from "../../components/VeilsCard/veilscard";
import { Helmet } from 'react-helmet'
import "./dist/VeilsPage.css"


import getMethods from "../../services/getMethods";

export class VeilsPage extends React.Component {



    constructor(props){
        super(props);
        this.state = {
            blankets: sessionStorage.getItem("veils")?JSON.parse(sessionStorage.getItem("veils")) : null,
            local_blankets: sessionStorage.getItem("veils")?JSON.parse(sessionStorage.getItem("veils")) : null
        }


        this.waitBlankets = this.waitBlankets.bind(this)
        this.blankets_capture = this.blankets_capture.bind(this)
    }



    async waitBlankets(){
        const response = await getMethods.getBlankets()

        if(response){
            this.setState({
                blankets: response,
                local_blankets:response
            },()=>{
                sessionStorage.setItem('veils',JSON.stringify(response))
            })
        }

        return response
    }
    blankets_capture(local_blankets){
        this.setState({local_blankets : local_blankets})
    }

    async componentDidMount(){
        try {
            if(!this.state.blankets)
                await this.waitBlankets()
            
        } catch (error) {
            return 0
        }
        
    }



    render(){
        return(
            <div className="products_page">
                <Helmet>
                    <title>Покривала. Магазин ритуальних послуг Реквієм у Львові</title>
                    <meta name="description" content="Ритуальні покривала у Львові. Оздобленні та стандартні ритуальні покривала для похорону у нашому магазині ритуальних послуг Реквієм. Ритуальні покривала та вклади в труну у Львові. Покривала у Львові та Львівській області."/>
                    <meta property="og:title" content="Покривала. Магазин ритуальних послуг Реквієм у Львові"/>
                    <meta property="og:description" content="Ритуальні покривала у Львові. Оздобленні та стандартні ритуальні покривала для похорону у нашому магазині ритуальних послуг Реквієм. Ритуальні покривала та вклади в труну у Львові. Покривала у Львові та Львівській області."/>
                </Helmet>
                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = 'background' />
                </header>
                <h2 className="product_view_page_title">Покривала</h2>
                <div className="product_view_page">
                    
                    <div className="products_view">
                        {this.state.blankets !== null? (
                            this.state.local_blankets.map((blanket,index) =>(
                                <VeilsCard setLink = {this.props.setLink} addToCart = {this.props.addToCart} cart={this.props.cart}  blanket = {blanket} key={index}/>
                            ))
                        ):'f'}

                    </div>
                </div>

                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}