import React from "react";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { FooterUp } from "../../components/FooterUp/footerUp";
import { ProductCard } from "../../components/ProductCard/productcard";
import "./dist/pg.css"
import { Helmet } from "react-helmet";


import getMethods from "../../services/getMethods";

export class ProductsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            coffin: sessionStorage.getItem("coffins")?JSON.parse(sessionStorage.getItem("coffins")) : null,
            local_coffin: sessionStorage.getItem("coffins")?JSON.parse(sessionStorage.getItem("coffins")) : null,
        }
        this.waitCoffin = this.waitCoffin.bind(this)
        this.coffin_capture = this.coffin_capture.bind(this)
    }






    async waitCoffin() {
        const response = await getMethods.getProducts()
        if(response){
          this.setState({
            coffin: response,
            local_coffin:response
          },()=>{
            sessionStorage.setItem('coffins',JSON.stringify(response))
          })
        }
    
        return response
    
      }

    coffin_capture(local_coffin){
        this.setState({local_coffin : local_coffin})
    }

    async componentDidMount(){
        try {
            if(!this.state.coffin){
                await this.waitCoffin()
            }
        } catch (error) {
            return 0
        }
    }




    render() {
        return(
            <div className="products_page">
                
                <Helmet>
                    <title>Труни або домовини. Магазин ритуальних послуг Реквієм у Львові.</title>
                    <meta name="description" content="Труни або домовини. Обирайте найшанованіші та гідні труни та домовини від нашого магазину ритуальних послуг Реквієм у Львові. Магазин ритуальних послуг Реквієм. Стандартні труни та домовини у Львові. " />
                    <meta property="og:title" content="Труни або домовини. Магазин ритуальних послуг Реквієм у Львові."/>
                    <meta property="og:description" content="Труни або домовини. Обирайте найшанованіші та гідні труни та домовини від нашого магазину ритуальних послуг Реквієм у Львові. Магазин ритуальних послуг Реквієм. Стандартні труни та домовини у Львові."/>
                </Helmet>

                <header className="header_background_selection">
                    <img src="./img/backgroundmain.png" alt="background" title = 'background' />
                </header>
                <div className="product_view_page">
                    <h1 className="product_view_page_title">Домовини</h1>
                    {/* <p className="description_w_text">
                    <h2 className="inline-heading">Домовина</h2> є останнім спочивальним місцем покійного і символізує його перехід у <h2 className="inline-heading">вічний спокій</h2>. Труни можуть бути різних розмірів і виготовлені з різних матеріалів, таких як <h2 className="inline-heading">натуральне дерево або ДСП.</h2> Вони можуть мати різні дизайни та оздоблення, відображаючи індивідуальність покійного. Вибір труни зазвичай залежить від бюджету, вподобань та <h2 className="inline-heading">культурних традицій родини.</h2>
                    </p> */}
                    
                    <div className="products_view">
                        {this.state.coffin !== null? (
                            this.state.local_coffin.map((coffin,index) =>(
                                <ProductCard setLink = {this.props.setLink} addToCart = {this.props.addToCart} cart={this.props.cart}  coffin = {coffin} key={index}/>
                            ))
                        ):'f'}

                    </div>
                </div>
                <div id="modal_w" className="modal_w">Товар додано у корзину</div>

                <FooterUp/>
                <FooterBot/>
            </div>
        )
    }
}