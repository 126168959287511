import React from 'react';
import './dist/headermain.css';

export class HeaderMain extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            user_name:'',
            user_phone:''
        }
    }
    nameKeeper = (e) => {
        let raw_value = e.target.value
        let default_value = raw_value.charAt(0).toUpperCase() + raw_value.slice(1)

        this.setState({user_name:default_value},()=>{
            this.props.nameBack(this.state.user_name)
        });
    }

    phoneKeeper = (e) =>{
        const rawValue = e.target.value;
        const formattedValue = rawValue
            .replace(/[^0-9+\-\s]/g, '')
            .replace(/\s+/g, '-')
        if(this.state.user_phone.length < 17){
            this.setState({user_phone: formattedValue},()=>{
                this.props.phoneBack(this.state.user_phone)
            })
        }else {
            this.setState((prevState) => ({
                user_phone: prevState.user_phone.slice(0, -1) 
            }));
        }
    }

    submitMainForm = () => {
        try {
            this.props.wait_send_or_call() ;
            this.setState({user_name: '', user_phone: ''})
        } catch (error) {
            this.setState({user_name: '', user_phone: ''})
        }
        
    }









    render(){
        return(
            <header className='header_main'>
                <div className="textpart">
                    <div className='title_block'>
                        <h1 className='h_title'>Магазин ритуальних <br/>послуг у Львові</h1>
                        <div className='title_two_block'>
                            <h2 className='title_second'>Земний шлях короткий - пам'ять вічна!</h2>
                        </div>
                    </div>
                    <div className='number_block'>
                    
                        <a href="tel:+380-67-676-03-64"><div className='numbers_main'><h2 className='number'>+380-67-673-03-64</h2></div></a>
                        <a href="tel:+380-98-249-60-50"><div className='numbers_main'><h2 className='number'>+380-98-249-60-50</h2></div></a>
                    </div>
                    <div className = 'adress_con'>
                        м. Львів вулиця Костя Левицького , 71
                    </div>
                </div>
                <div className='contact_header_form' action="">
                    <div className='inputs_main_form_h'>
                        <input value={this.state.user_name} onChange={this.nameKeeper} title="Ваше ім'я" placeholder='memory' id='name_main' name='name_main' className='main_inputs' type="text" required/>
                        <label htmlFor="name_main" className='main_label'>Ім'я</label>
                    </div>
                    <div className='inputs_main_form_h'>
                        <input value={this.state.user_phone} onChange={this.phoneKeeper} title="Ваш номер телефону" placeholder='memory' id='phone_main' name='phone_main' className='main_inputs' type="text" required />
                        <label htmlFor="phone_main" className='main_label'>Ваш номер</label>
                    </div>
                    <button onClick={this.submitMainForm} className='contact_button'>Замовити</button>

                </div>
            </header>
        )
    }
}