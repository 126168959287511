import React from 'react';
import "./dist/cart.css";
import { ProductNavBar } from "../../components/ProductNavBar/pnb";
import { FooterBot } from "../../components/FooterBot/footerbot";
import { CartCard } from '../../components/CartCard/cartCard';
import { CartCardMenu } from '../../components/CartMenuCard/cartcardmenu';
import BuyBlock from '../../components/BuyBlock/buyblock';

export class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.carcarcan = React.createRef()
        this.menuOpener = this.menuOpener.bind(this)
        // this.menusizer = this.menusizer.bind(this)  
        this.state = {
            menu_status: 1
        }
    }


    menuOpener() {
        let menu = document.getElementById("cart_menu")
        let real_menu_text = document.getElementById("cart_text_cart_really_cart")
        if (this.state.menu_status === 1) {
            if(window.innerWidth > 426){
                document.getElementById("card_car_con").style.width = "200vh"
                document.getElementById("card_car_con").style.padding = "0 2.7vh 0 10.9vh"
            }
            else{
                document.getElementById("card_car_con").style.width = "100vw"
                document.getElementById("card_car_con").style.padding = "0 .5vh 0 .5vh"

            }
            real_menu_text.style.opacity= '0'
            menu.style.width = '0'
            document.getElementById("cart_menu_close").style.transform = "rotate(-180deg)"
            this.setState({ menu_status: 0 })
            
        }
        else {
            if(window.innerWidth > 426){
                document.getElementById("card_car_con").style.width = "100%"
                document.getElementById("card_car_con").style.padding = "0 2.7vh 0 4.8vh"
                menu.style.width = '56vh'
            }
            else{
                document.getElementById("card_car_con").style.width = "0"
                menu.style.width = '100%'
            }
            real_menu_text.style.opacity = '100%'
            this.setState({ menu_status: 1 })
            document.getElementById("cart_menu_close").style.transform = "rotate(0deg)"
            
        }
    }

    // menusizer(){
    //     document.getElementById("cart_menu").style.height = `${(this.carcarcan.current.getBoundingClientRect().height)+ 151 }px`
    // }



    // componentDidMount() {
    //    this.menusizer()
    //   }


      

    render() {
        return (
            <div className='cart_page'>
                <ProductNavBar cart_keeper={this.props.cart_keeper} cart={this.props.cart} />
                <header className="header_background_cart">
                    <img  src="./img/backgroundmain.png" alt="" />
                </header>
                <main className='cart_section'>
                    <img id='cart_menu_close' className='cart_menu_closer' onClick={this.menuOpener} src="./img/back_btn.svg" alt="" />
                    <div id='cart_menu' className='menu'>
                        <div className='menu_controller'>
                            <div className='symulator'></div>
                            <div id='cart_text_cart_really_cart'>Корзина</div>
                        </div>

                        {this.props.cart.reverse().map((e,index)=>(
                            <CartCardMenu key={index} removeFromCart={this.props.removeFromCart} fullData={this.props.cart} index={index} data={e} />
                        ))}
                    
                    </div>
                    <div ref={this.carcarcan} id="card_car_con" className='cart_card_con'>

                        {this.props.local_cart.reverse().map((e,index)=>(
                            <CartCard key={index}  removeFromCart={this.props.removeFromCart} fullData={this.props.local_cart} index={index} data={e} />
                        ))}
                        <BuyBlock phoneBack={this.props.phoneBack} nameBack={this.props.nameBack} wait_send_or_call = {this.props.wait_send_or_call} cart = {this.props.cart}/>

                    </div>

                </main>

                <FooterBot />
            </div>
        )
    }
}